/**
 * Created by gka on 05.05.17.
 */

export class Constants {
  public static categories = [95, 96, 97, 99, 381262, 381263, 381274, 381275, 381277, 381278, 381279, 381280, 381281, 381282, 381283,
    381284, 381285, 381286, 381287];

  public static region = 'RADREVIERRUHR';

  /* BASE URLs */
  public static URL_BASE = 'https://tourenplaner.dev.eftas.services/';
  public static URL_BASE_API = Constants.URL_BASE.replace(/\/\//, '//api.');
  public static URL_BASE_AUTH = Constants.URL_BASE_API + 'auth/';
  public static URL_BASE_GEOSEARCH = 'https://geodaten.metropoleruhr.de/';
  public static URL_BASE_MEDIA = Constants.URL_BASE_API + 'media/';
  public static URL_BASE_RESOURCES = Constants.URL_BASE_API + 'resourceserver/';
  public static URL_BASE_ROUTING = Constants.URL_BASE_API + 'routing/';
  public static URL_BASE_SEARCH = Constants.URL_BASE_API + 'search/';

  /* CATEGORY */
  public static URL_CATEGORIES = Constants.URL_BASE_RESOURCES + 'category/list?region=' + Constants.region;

  /* HIGHLIGHTS */
  public static URL_HIGHLIGHT_ALL = Constants.URL_BASE_RESOURCES + 'highlight/all?region=' + Constants.region;
  public static URL_HIGHLIGHT_POI = Constants.URL_BASE_RESOURCES + 'highlight/poi?region=' + Constants.region;
  public static URL_HIGHLIGHT_TOUR = Constants.URL_BASE_RESOURCES + 'highlight/tour?region=' + Constants.region;

  /* MEDIA */
  public static URL_IMAGE = Constants.URL_BASE_MEDIA + 'content';

  /* ROUTING */
  public static URL_ROUTING_AB = Constants.URL_BASE_ROUTING + 'abdistance';
  public static URL_ROUTING_AB_SHORTEST = Constants.URL_BASE_ROUTING + 'shortest?region=' + Constants.region;
  public static URL_ROUTING_GPX = Constants.URL_BASE_ROUTING + 'onlinestringbyfile';
  public static URL_ROUTING_ONTOUR = Constants.URL_BASE_ROUTING + 'ontour?region=' + Constants.region;
  public static URL_ROUTING_ROUNDTRIP = Constants.URL_BASE_ROUTING + 'roundtrip';

  /* ROUTE */
  public static URL_CUSTOM_ROUTE = Constants.URL_BASE_RESOURCES + 'route/customroute';
  public static URL_DOWNLOAD_TOUR_GPX = Constants.URL_BASE_RESOURCES + 'route/publicroute?format=gpx';
  public static URL_DOWNLOAD_TOUR_KML = Constants.URL_BASE_RESOURCES + 'route/publicroute?format=kml';

  /* SEARCH */
  public static URL_SEARCH_POIS = Constants.URL_BASE_SEARCH + 'poi?region=' + Constants.region;
  public static URL_SEARCH_POIS_TITLE = Constants.URL_BASE_SEARCH + 'poi/title?region=' + Constants.region;
  public static URL_SEARCH_TOURS = Constants.URL_BASE_SEARCH + 'tour?region=' + Constants.region;

  /* TOKEN */
  public static URL_TOKEN = Constants.URL_BASE_AUTH + 'oauth/token';

  /* ACCOUNT */
  public static URL_ACCOUNT = Constants.URL_BASE_RESOURCES + 'account/';
  public static URL_ACCOUNT_ACTIVATE = Constants.URL_ACCOUNT + 'activate/';
  public static URL_ACCOUNT_DELETE = Constants.URL_ACCOUNT + 'delete/';
  public static URL_USER_REGISTER = Constants.URL_ACCOUNT + 'register';  // TODO: This may be renamed to URL_ACCOUNT_REGISTER

  /* USER */
  public static URL_USER = Constants.URL_BASE_RESOURCES + 'user/';
  public static URL_USER_ME = Constants.URL_USER + 'me';
  public static URL_USER_FACEBOOK = Constants.URL_BASE_API + 'auth/login/facebook';
  public static URL_USER_LOGOUT = Constants.URL_BASE_API + 'auth/logout';

  /* PASSWORD */
  public static URL_LOST_PASSWORD = Constants.URL_BASE_RESOURCES + 'password/lost/';
  public static URL_NEW_PASSWORD = Constants.URL_BASE_RESOURCES + 'password/reset/';

  /* POI */
  public static URL_POI = Constants.URL_BASE_RESOURCES + 'poi/by-id/';
  public static URL_POIS = Constants.URL_BASE_RESOURCES + 'poi/list?region=' + Constants.region + '&category=' + Constants.categories.join(',');

  /* TOUR */
  public static URL_TOUR = Constants.URL_BASE_RESOURCES + 'tour/';
  public static URL_TOURS = Constants.URL_BASE_RESOURCES + 'tour/list?region=' + Constants.region;

  /* GEOCODER */
  public static URL_GEOCODER = 'https://api.opencagedata.com/geocode/v1/json';

  /* GEOSEARCH */
  public static URL_GEOSEARCH_ALKIS = Constants.URL_BASE_GEOSEARCH + 'suche?q=';
  public static URL_GEOSEARCH_POIS = Constants.URL_BASE_GEOSEARCH + 'poi_radrevierruhr?q=';

  /* MAPTILES */
  public static URL_MAPTILES_OPENMAPTILES = 'assets/json/map-style-openmaptiles.json';
  public static URL_MAPTILES_ORTHOFOTOS = 'assets/json/map-style-orthofotos.json';
  public static URL_MAPTILES_STADTPLANWERK = 'assets/json/map-style-stadtplanwerk.json';

  /* PROXY */
  public static URL_PROXY = Constants.URL_BASE.replace(/\/\//, '//proxy.');

  /* WEATHER */
  public static URL_WEATHER = 'https://brightsky.web.eftas.services/weather';

  /* NAVIGATION */
  public static LAYER_NAVIGATION_ARROW_LINES = 'navigationNodesLineBuffer';
  public static LAYER_NAVIGATION_ARROW_HEADS = 'arrowHeadsLayer';
  public static LAYER_NAVIGATION_OWN_POSITION = 'arrowLayer';
  public static LAYER_ROUTING_CUSTOM_ROUTE = 'custom-route';
  public static LAYER_ROUTING_DIRECTION = 'direction-layer';


  // map bounds (including römer-lippe-tour)
  public static MIN_LNG = 6.2141;
  public static MIN_LAT = 51.1656;
  public static MAX_LNG = 8.9187;
  public static MAX_LAT = 51.9861;

  // search bounds
  public static SEARCH_MIN_LNG = 6.2141;
  public static SEARCH_MIN_LAT = 51.1656;
  public static SEARCH_MAX_LNG = 8.1451;
  public static SEARCH_MAX_LAT = 51.8660;

  public static ZOOM = 10;

  public static MAP_SCALES = [116179.070, 58089.535, 29044.767, 14522.384, 7261.192, 3630.596, 1815.298, 907.649,
    453.824, 226.912, 113.456, 56.728, 28.364, 14.182, 7.091, 3.546, 1.773, 0.886, 0.443, 0.222, 0.111, 0.055, 0.028];

  public static GEOLOCATION_OPTIONS = {
    enableHighAccuracy: true,
    maximumAge: 30000,
    timeout: 10000
  };

  public static URL_NEXTBIKE = 'https://api.nextbike.net/maps/nextbike-official.json';
  public static regexUrl = /(?:https?:\/\/)?((?:[a-zA-Z0-9-]{2,}[.\/])+[a-z-\/]{2,})/g;
}
