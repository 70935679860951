/**
 * DOM class providing some static helper functions which are not present in current typescript bindings for
 * maplibre-gl js library
 */
export class DOM {
  public static create(tagName: string, className?: string, container?: HTMLElement) {
    const el = window.document.createElement(tagName);
    if (className) {
      el.className = className;
    }
    if (container) {
      container.appendChild(el);
    }
    return el;
  }

  public static remove(node: HTMLElement) {
    if (node.parentNode) {
      node.parentNode.removeChild(node);
    }
  }
}
