import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';

const routes: Routes = [
  {
    path: 'start',
    loadChildren: () => import('./pages/home/home.module').then(m => m.HomePageModule)
  },
  {
    // path: 'info/:activeTab',
    path: 'info',
    loadChildren: () => import('./pages/about/about.module').then(m => m.AboutPageModule)
  },
  {
    path: 'aktivieren/:token',
    loadChildren: () => import('./pages/account-activate/account-activate.module').then(m => m.AccountActivatePageModule)
  },
  {
    path: 'haltestelle/:busStopId',
    loadChildren: () => import('./pages/bus-stop/bus-stop.module').then(m => m.BusStopPageModule)
  },
  {
    path: 'loeschen/:token',
    loadChildren: () => import('./pages/account-delete/account-delete.module').then(m => m.AccountDeletePageModule)
  },
  {
    path: 'entdecken',
    loadChildren: () => import('./pages/highlights/highlights.module').then(m => m.HighlightsPageModule)
  },
  {
    path: 'anmelden',
    loadChildren: () => import('./pages/login/login.module').then(m => m.LoginPageModule)
  },
  {
    path: 'karte',
    loadChildren: () => import('./pages/map/map.module').then(m => m.MapPageModule)
  },
  // {
  //     path: 'zuruecksetzen/:token',
  //     loadChildren: () => import('./pages/account-reset-password/account-reset-password.module')
  //         .then(m => m.AccountResetPasswordPageModule)
  // },
  {
    path: 'wiederherstellen/:token',
    loadChildren: () => import('./pages/account-recovery/account-recovery.module').then(m => m.AccountRecoveryPageModule)
  },
  {
    path: 'orte',
    loadChildren: () => import('./pages/pois/pois.module').then(m => m.PoisPageModule)
  },
  {
    path: 'ort-details/:poiId',
    loadChildren: () => import('./pages/poi-details/poi-details.module').then(m => m.PoiDetailsPageModule)
  },
  {
    path: 'tourenplaner/:tourId',
    loadChildren: () => import('./pages/routing/routing.module').then(m => m.RoutingPageModule)
  },
  {
    path: 'tourenplaner',
    loadChildren: () => import('./pages/routing/routing.module').then(m => m.RoutingPageModule)
  },
  {
    path: 'suche',
    loadChildren: () => import('./pages/search/search.module').then(m => m.SearchPageModule)
  },
  {
    path: 'einstellungen',
    loadChildren: () => import('./pages/settings/settings.module').then(m => m.SettingsPageModule)
  },
  {
    path: 'touren',
    loadChildren: () => import('./pages/tours/tours.module').then(m => m.ToursPageModule)
  },
  {
    path: 'tour-details/:tourId',
    loadChildren: () => import('./pages/tour-details/tour-details.module').then(m => m.TourDetailsPageModule)
  },
  {
    path: 'benutzerprofil',
    loadChildren: () => import('./pages/user-profile/user-profile.module').then(m => m.UserProfilePageModule)
  },
  {
    path: 'favoriten',
    loadChildren: () => import('./pages/user-favorites/user-favorites.module').then(m => m.UserFavoritesPageModule)
  },
  {
    path: 'downloads',
    loadChildren: () => import('./pages/user-downloads/user-downloads.module').then( m => m.UserDownloadsPageModule)
  },
  {
    path: 'eigene-touren',
    loadChildren: () => import('./pages/user-tours/user-tours.module').then(m => m.UserToursPageModule)
  },
  // {
    //     path: 'maengelmelder',
    //     loadChildren: () => import('./pages/user-report/user-report.module').then( m => m.UserReportPageModule)
    // },
    {   // redirect to `first-component`
        path: '',
        redirectTo: 'start',
        pathMatch: 'full'
    },
    {   // Wildcard route for a 404 page
        path: '**',
        loadChildren: () => import('./pages/page-not-found/page-not-found.module').then(m => m.PageNotFoundPageModule)
    },
  {
    path: 'image-modal',
    loadChildren: () => import('./components/item-details/modals/image-modal/image-modal.module').then( m => m.ImageModalPageModule)
  }

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {preloadingStrategy: PreloadAllModules})
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
