import {Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {IonSearchbar} from '@ionic/angular';

@Component({
  selector: 'app-toolbar-searchbar',
  template: `
    <ion-searchbar placeholder="{{placeholder}}" (ionInput)="search($event)"
                   [ngClass]="{'loading': loading}"></ion-searchbar>
    <ion-spinner *ngIf="loading"></ion-spinner>
  `,
  styleUrls: ['./toolbar-searchbar.component.scss'],
})
export class ToolbarSearchbarComponent {

  @Input() loading: boolean;
  @Input() placeholder: string;
  @Output() inputChange: EventEmitter<string> = new EventEmitter();

  constructor() {
  }

  @ViewChild(IonSearchbar) set searchBar(bar: IonSearchbar) {
    setTimeout(() => {
      bar.setFocus();
    }, 500);
  }

  async search(ev: any) {
    if (ev.target.value.length >= 3) {
      this.inputChange.emit(ev.target.value.toLowerCase());
    }
  }
}
