import {Component, Input} from '@angular/core';
import {ModalController} from '@ionic/angular';

@Component({
  selector: 'app-about-privacy',
  templateUrl: './about-privacy.page.html',
  styleUrls: ['./about-privacy.page.scss'],
})
export class AboutPrivacyPage {

  @Input() isModal = false;

  constructor(
    private modalCtrl: ModalController
  ) {
  }

  async dismissModal() {
    await this.modalCtrl.dismiss();
  }

}
