import {Component} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {DomSanitizer, SafeHtml} from '@angular/platform-browser';
import {NavParams, PopoverController} from '@ionic/angular';

export class IframeData {
  url: string;
  sanitized: SafeHtml;
}

@Component({
  selector: 'app-embed-popover',
  templateUrl: 'embed-popover.component.html'
})
export class EmbedPopoverComponent {
  linkInfo = '/tour-details/';
  linkMap = '/karte?tourID=';
  embedInfo: IframeData;
  embedMap: IframeData;
  size = {
    width: 640,
    height: 400
  };
  mapView = true;

  constructor(
    private navParams: NavParams,
    private translate: TranslateService,
    // private viewCtrl: ViewController,
    private popoverCtrl: PopoverController,
    private sanitizer: DomSanitizer) {
  }

  ionViewWillEnter() {
    const currentPath = window.location.href.split('/');
    const baseUrl = currentPath[0] + '//' + currentPath[2];

    this.linkInfo = baseUrl + this.linkInfo;
    this.linkMap = baseUrl + this.linkMap;

    const url = this.navParams.get('url');
    const tourId = url.match(/.*\/(.*)/)[1];
    this.linkMap = this.linkMap + tourId + '&header=false';
    this.linkInfo = this.linkInfo + tourId + '/?header=false';
    this.updateCode();
  }

  updateCode() {
    this.embedInfo = this.createIframe(this.linkInfo, this.size.width, this.size.height);
    this.embedMap = this.createIframe(this.linkMap, this.size.width, this.size.height);
  }

  createIframe(baseUrl: string, width: number, height: number, style?: string): IframeData {
    const iFrameData: IframeData = {url: '', sanitized: null};
    style = style || 'border: none; overflow: hidden';
    iFrameData.url = `<iframe class="embed-iframe" src=${baseUrl}&width=${width}/ width="${width}" height="${height}" style="${style}"></iframe>`;
    iFrameData.sanitized = this.sanitizer.bypassSecurityTrustHtml(iFrameData.url);
    return iFrameData;
  }

  async close() {
    await this.popoverCtrl.dismiss();
  }

}
