// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.spinner-ios {
  position: absolute;
  right: 50px;
  bottom: 11px;
}

.spinner-ios * {
  stroke: #fff;
}

ion-spinner {
  position: absolute;
  --color: #fff;
}
ion-spinner.ios {
  right: 59px;
  top: 8px;
}
ion-spinner.md {
  right: 69px;
  top: 13.5px;
}

ion-searchbar.md {
  --background: transparent;
  --box-shadow: none;
  --cancel-button-color: #fff;
  --clear-button-color: #fff;
  --color: #fff;
  --icon-color: #fff;
  --placeholder-color: #fff;
}
ion-searchbar.ios {
  height: 40px;
  padding: 2px 12px;
}
ion-searchbar.loading {
  --cancel-button-color: transparent !important;
  --clear-button-color: transparent !important;
}

:host.full {
  flex: 1;
}
:host.full .searchbar {
  height: 100%;
}

@media only screen and (orientation: landscape) {
  :host {
    position: relative;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/components/toolbar-searchbar/toolbar-searchbar.component.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,WAAA;EACA,YAAA;AACF;;AAEA;EACE,YAAA;AACF;;AAEA;EACE,kBAAA;EACA,aAAA;AACF;AACE;EACE,WAAA;EACA,QAAA;AACJ;AAEE;EACE,WAAA;EACA,WAAA;AAAJ;;AAKE;EACE,yBAAA;EACA,kBAAA;EACA,2BAAA;EACA,0BAAA;EACA,aAAA;EACA,kBAAA;EACA,yBAAA;AAFJ;AAKE;EACE,YAAA;EACA,iBAAA;AAHJ;AAME;EACE,6CAAA;EACA,4CAAA;AAJJ;;AAQA;EACE,OAAA;AALF;AAOE;EACE,YAAA;AALJ;;AASA;EACE;IACE,kBAAA;EANF;AACF","sourcesContent":[".spinner-ios {\n  position: absolute;\n  right: 50px;\n  bottom: 11px;\n}\n\n.spinner-ios * {\n  stroke: #fff;\n}\n\nion-spinner {\n  position: absolute;\n  --color: #fff;\n\n  &.ios {\n    right: 59px;\n    top: 8px;\n  }\n\n  &.md {\n    right: 69px;\n    top: 13.5px;\n  }\n}\n\nion-searchbar {\n  &.md {\n    --background: transparent;\n    --box-shadow: none;\n    --cancel-button-color: #fff;\n    --clear-button-color: #fff;\n    --color: #fff;\n    --icon-color: #fff;\n    --placeholder-color: #fff;\n  }\n\n  &.ios {\n    height: 40px;\n    padding: 2px 12px;\n  }\n\n  &.loading {\n    --cancel-button-color: transparent !important;\n    --clear-button-color: transparent !important;\n  }\n}\n\n:host.full {\n  flex: 1;\n\n  .searchbar {\n    height: 100%;\n  }\n}\n\n@media only screen and (orientation: landscape) {\n  :host {\n    position: relative;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
