import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'distance'
})
export class DistancePipe implements PipeTransform {

  transform(value: number): string {
    if (value) {
      value = Math.round(value / 10) * 10; // round by 10
      if (value < 1000) {
        return value + '&thinsp;m';
      } else {
        if (value <= 5000) {
          value = Math.round(value / 100) / 10; // round by 100, convert to km
        } else {
          value = Math.round(value / 1000); // round by 1000, convert to km
        }
        let strValue: string = value.toString();
        if (value % 1 !== 0) {
          strValue = strValue.replace(/\./, ',');
        }
        return strValue + '&thinsp;km';
      }
    } else {
      return '';
    }
  }

}
