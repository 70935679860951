// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.danger .label {
  color: var(--ion-color-danger);
}`, "",{"version":3,"sources":["webpack://./src/app/components/list-popover/list-popover.component.scss"],"names":[],"mappings":"AAAA;EACE,8BAAA;AACF","sourcesContent":[".danger .label {\n  color: var(--ion-color-danger);\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
