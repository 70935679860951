import {Injectable} from '@angular/core';
import {Point} from 'geojson';
import {HttpClient} from '@angular/common/http';
import {Constants} from '../var/constants';
import {LngLat} from 'maplibre-gl';
import {TranslateService} from '@ngx-translate/core';
import {firstValueFrom} from "rxjs";

export interface MarkerInfo {
  displayName: string;
  city: string;
  suburb?: string;
  address?: string;
  title: string;
  geom: Point;
  coordinateString: string;
  result: any;
  significance?: number;
}

@Injectable({
  providedIn: 'root'
})
export class GeocodingService {

  apiKey = 'c68bb81f47ff4dcab64937d968c7ee3d';

  constructor(
    private http: HttpClient,
    private translate: TranslateService
  ) {
  }

  async search(input: string): Promise<MarkerInfo[]> {
    const response: any = await firstValueFrom(this.http.get(Constants.URL_GEOCODER, {
      params: {
        key: this.apiKey,
        q: input,
        bounds: `${Constants.SEARCH_MIN_LNG},${Constants.SEARCH_MIN_LAT},${Constants.SEARCH_MAX_LNG},${Constants.SEARCH_MAX_LAT}`,
        countrycode: 'de'
      }
    }));
    if (response.status.code === 200 && response.results.length >= 1) {
      const markerInfos: MarkerInfo[] = [];
      for (const result of response.results) {
        const markerInfo = await this.getMarkerInfo(result);
        markerInfos.push(markerInfo);
      }
      return markerInfos;
    } else {
      return [];
    }
  }

  async reverse(lngLat: LngLat | number[]): Promise<MarkerInfo> {
    let lng = lngLat[0] || (lngLat as LngLat).lng;
    let lat = lngLat[1] || (lngLat as LngLat).lat;
    lng = Math.round(lng * 1000000) / 1000000;
    lat = Math.round(lat * 1000000) / 1000000;
    const response: any = await firstValueFrom(this.http.get(Constants.URL_GEOCODER, {
      params: {
        key: this.apiKey,
        q: `${lat},${lng}`,
        abbrv: '1'
      }
    }));
    if (response.status.code === 200 && response.results.length >= 1) {
      return this.getMarkerInfo(response.results[0]);
    }
  }

  private async getMarkerInfo(result: any): Promise<MarkerInfo> {
    let displayName;
    let city;
    let suburb;
    let address;
    let title;
    let coordinateString;
    let geom;
    // remove trailing country from display name
    displayName = result.formatted.replace(/, (Deutschland|Germany|Duitsland)$/, '');
    // remove postal code from display name
    displayName = displayName.replace(/\d{5} (\w+)/, '$1');
    // extract city name
    if (result.components.village) {
      city = result.components.village;
    } else if (result.components.town) {
      city = result.components.town;
    } else if (result.components.city) {
      city = result.components.city;
    } else {
      city = await firstValueFrom(this.translate.get('services.geocoding.unknown-city'));
    }
    // set suburb name if !== city
    if (result.components.suburb && result.components.suburb !== city) {
      suburb = result.components.suburb;
    }
    // set address
    if (result.components.road) {
      address = result.components.road;
    } else if (result.components.path) {
      address = result.components.path;
    } else if (result.components.pedestrian) {
      address = result.components.pedestrian;
    }
    if (address) {
      if (result.components.house_number) {
        address += ` ${result.components.house_number}`;
      }
    } else {
      address = await firstValueFrom(this.translate.get('services.geocoding.unknown-address'));
    }

    // extract point name
    if (result.components[result.components._type]) {
      title = result.components[result.components._type];
    } else if (result.components.unknown) {
      title = result.components.unknown;
    } else if (address !== await firstValueFrom(this.translate.get('services.geocoding.unknown-address'))) {
      title = address;
    } else if (city) {
      title = city;
      if (suburb) {
        title += `, ${suburb}`;
      }
    } else {
      title = await firstValueFrom(this.translate.get('services.geocoding.unknown-place'));
    }

    // set geometry
    geom = {
      type: 'Point',
      coordinates: [result.geometry.lng, result.geometry.lat]
    };

    // set coordinate string for display
    coordinateString = Math.round(result.geometry.lat * 10000) / 10000 + ' ';
    coordinateString += Math.round(result.geometry.lng * 10000) / 10000;

    const markerInfo: MarkerInfo = {displayName, city, title, geom, coordinateString, result};
    if (suburb) {
      markerInfo.suburb = suburb;
    }
    if (address && address !== title) {
      markerInfo.address = address;
    }
    return markerInfo;
  }
}
