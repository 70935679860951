import {Injectable} from '@angular/core';
import {AlertController} from '@ionic/angular';

import {TranslateService} from '@ngx-translate/core';
import {LineString, Point} from 'geojson';
import {GeoJSONSource, LngLat, Marker} from 'maplibre-gl';
import * as turf from '@turf/turf';

import {EventsService} from './events.service';
import {MapService} from './map.service';
import {PositionService} from './position.service';
import {UtilService} from './util.service';
import {
    Direction,
    NavigationInstruction,
    NavigationState,
    PositionBuffer,
    RoutingSolution,
    SnappedLocation,
} from '../lib/types/radrevier-ruhr';
import {Constants} from '../var/constants';
import {KeepAwake} from '@capacitor-community/keep-awake';
import {Position} from '@capacitor/geolocation';
import {Capacitor} from '@capacitor/core';
import {firstValueFrom} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  positionMarkerAdded = false;
  // Proximity distance in metres
  proximityDistance = 30;
  currentNodeAndPosition = { currentNode: 0, position: 'before', distance: undefined };
  state = NavigationState.OFF;
  positionBuffer: PositionBuffer = new PositionBuffer(2);
  audio: HTMLAudioElement;
  routeLeftTimeoutId: any;
  // the current segment of the route line string
  // TODO: Store in some class (currentNodeAndPosition)
  currentSegment = 0;

  constructor(
    private alertCtrl: AlertController,
    private events: EventsService,
    private mapService: MapService,
    private positionService: PositionService,
    private util: UtilService,
    private translate: TranslateService
  ) {}

  /**
   * Return the distance to the next node rounded to the nearest 10 metres
   */
  static roundedDistanceToNextNode(snappedPoint: Point, nextInstruction: Point, route: LineString): number {
    const distance = UtilService.distanceOnLineV2(snappedPoint, nextInstruction, route, 0);
    return Math.abs(Math.round(distance / 10) * 10);
  }

  /**
   * Approximate remaining distance on tour.
   *
   * To circumvent issues related to the user's real position on tour - which is ambigious on tours with overlapping segments - the number of the current segment is used. Distance to the start and to the end point of that segment from the current position are compared to get an approximation of the remaining distance on tour.
   *
   * @param lngLat - the user's current position
   * @param route
   * @param currentSegment - the segment the user is currently snapped to
   * @returns An approximation of the remaining distance on tour
   */
  static approximateRemainingDistance(lngLat: LngLat, route: RoutingSolution, currentSegment: number) {
    const routeCoords = route.geom.coordinates;
    const currentSegmentStartPoint = turf.point([routeCoords[currentSegment][0], routeCoords[currentSegment][1]]);
    const currentSegmentEndPoint = turf.point([routeCoords[currentSegment + 1][0], routeCoords[currentSegment + 1][1]]);
    const currentPosition = turf.point([lngLat.lng, lngLat.lat]);
    const distToCurrentSegmentStartPoint: number = turf.distance(currentSegmentStartPoint, currentPosition);
    const distToCurrentSegmentStopPoint: number = turf.distance(currentPosition, currentSegmentEndPoint);
    const index: number =
      distToCurrentSegmentStartPoint < distToCurrentSegmentStopPoint ? currentSegment : currentSegment + 1;
    const remainingRouteGeom: LineString = {
      type: 'LineString',
      coordinates: route.geom.coordinates.slice(index),
    };
    const length = turf.length(
      { type: 'Feature', geometry: remainingRouteGeom, properties: null },
      { units: 'meters' }
    );
    console.log('remaining distance', length, 'currentSegment', currentSegment, 'index', index);
    return length;
  }

  /**
   * Build verbose navigation instruction (necessary for routing page)
   * and short navigation instruction with audio files (necessary for turn-by-turn navigation).
   */
  async buildInstructions(instruction: NavigationInstruction, distance?: number) {
    if (instruction) {
      // First part is the distance
      instruction.shortTextPart1 = '';
      instruction.shortTextPart2 = '';
      if (distance !== undefined && distance >= 10) {
        instruction.shortTextPart1 += UtilService.capitalize(
          await firstValueFrom(this.translate.get('services.navigation.distance', { distance }))
        );
      } else if (distance < 10) {
        instruction.shortTextPart1 += await firstValueFrom(this.translate.get('services.navigation.now'));
      }
      if (instruction.start === true) {
        // Start node
        instruction.shortTextPart1 = await firstValueFrom(this.translate.get('services.navigation.instruction-DEPART'));
        instruction.longText = await firstValueFrom(this.translate.get('services.navigation.instruction-DEPART'));
        instruction.iconcls = 'depart';
        instruction.audioFile = 'assets/audio/start.m4a';
      } else if (instruction.stop === true) {
        // End node
        instruction.shortTextPart1 = await firstValueFrom(this.translate.get('services.navigation.instruction-ABSTOP'));
        instruction.longText = await firstValueFrom(this.translate.get('services.navigation.instruction-ABSTOP'));
        instruction.iconcls = 'arrive';
        instruction.audioFile = 'assets/audio/end.m4a';
      } else {
        // Any other node
        // TODO: multilanguage audio files
        if (instruction.direction === Direction.STRAIGHT_AHEAD) {
          instruction.iconcls = 'continue';
          instruction.audioFile = 'assets/audio/straight_ahead.m4a';
        }
        if (instruction.direction === Direction.SLIGHT_LEFT) {
          instruction.iconcls = 'turn_slight_left';
          instruction.audioFile = 'assets/audio/slight_left.m4a';
        }
        if (instruction.direction === Direction.LEFT) {
          instruction.iconcls = 'turn_left';
          instruction.audioFile = 'assets/audio/left.m4a';
        }
        if (instruction.direction === Direction.SHARP_LEFT) {
          instruction.iconcls = 'turn_sharp_left';
          instruction.audioFile = 'assets/audio/sharp_left.m4a';
        }
        if (instruction.direction === Direction.BACKWARDS) {
          instruction.iconcls = 'continue_uturn';
        }
        if (instruction.direction === Direction.SHARP_RIGHT) {
          instruction.iconcls = 'turn_sharp_right';
          instruction.audioFile = 'assets/audio/sharp_right.m4a';
        }
        if (instruction.direction === Direction.RIGHT) {
          instruction.iconcls = 'turn_right';
          instruction.audioFile = 'assets/audio/right.m4a';
        }
        if (instruction.direction === Direction.SLIGHT_RIGHT) {
          instruction.iconcls = 'turn_slight_right';
          instruction.audioFile = 'assets/audio/slight_right.m4a';
        }
      }
      // Append street name if available
      if (instruction.name) {
        const streetName = (await firstValueFrom(this.translate.get('services.navigation.on'))) + instruction.name;
        instruction.shortTextPart2 += streetName;
        instruction.longText += streetName;
      } else if (
        instruction.direction &&
        instruction.direction !== Direction.STRAIGHT_AHEAD &&
        instruction.direction !== Direction.BACKWARDS
      ) {
        const turn = await firstValueFrom(this.translate.get('services.navigation.turn'));
        instruction.shortTextPart2 += turn;
        instruction.longText += turn;
      }
      // Append distance to instruction
      if (distance !== undefined && distance >= 10) {
        const distanceText = await firstValueFrom(this.translate.get('services.navigation.distance', { distance }));
        instruction.longText += ' ' + distanceText;
        if (instruction.stop === true) {
          instruction.shortTextPart2 = distanceText;
        }
      }
    }
  }

  /**
   * Await navigation entry at a certain location.
   * An awaited navigation turns active when the user approaches proximity to the route and this proximity is stable.
   */
  async awaitNavigation(route: RoutingSolution, instructions: NavigationInstruction[]) {
    this.state = NavigationState.AWAITED;
    console.log('State: ' + this.state);

    try {
      await this.positionService.getPosition();
    } catch (e) {
      await this.util.handleErrorMsg('Der Gerätestandort konnte nicht bestimmt werden.');
      this.state = NavigationState.OFF;
    }

    if (this.state === NavigationState.AWAITED) {
      let geolocation: GeolocationPosition;
      if (Capacitor.isNativePlatform) {
        try {
          await KeepAwake.keepAwake();
        } catch (e) {
          console.log(e);
        }

        this.positionService
          .startBackgroundWatch()
          .subscribe((geolocation: GeolocationPosition) => this.navigate(route, geolocation, instructions));
      } else {
        this.positionService
          .watchPosition()
          .subscribe((geolocation: GeolocationPosition) => this.navigate(route, geolocation, instructions));
      }

      await this.setupNavigationView(route);

      // Reset instruction to pending instruction if instruction was set before
      const pendingInstructionText = 'Start in der Nähe der Tour!';
      const pendingInstruction = new NavigationInstruction(pendingInstructionText, 'depart');
      this.events.publish('navigation:update-instruction-panel', pendingInstruction, this.state);

      // Reset remaining distance if it was calculated before
      const distance = null;
      this.events.publish('navigation:remaining-distance', distance);
    }
  }

  /**
   * React on changes of the user's location and navigate the user.
   */
  async navigate(route: RoutingSolution, position: Position, instructions: NavigationInstruction[]) {
    if (position.coords !== undefined) {
      this.positionBuffer.add(position);
      const currentLngLat = new LngLat(position.coords.longitude, position.coords.latitude);
      const snappedLocation: SnappedLocation = await this.snapToRouteV2(
        position,
        route,
        10,
        this.currentSegment
      );

      // If user is near enough to snap to route
      if (snappedLocation !== null) {
        const snappedLngLat: LngLat = snappedLocation.lngLat;
        // Add position marker to snapped position if snapped
        this.showOwnPosition(snappedLngLat);
        const speed: number = position.coords.speed ? position.coords.speed : 4.1667 // 15 km/h
        const currentNodeAndPosition = this.getCurrentNodeAndPositionV2(snappedLngLat, route, this.currentSegment, speed);
        if (this.state === NavigationState.LEFT || this.state === NavigationState.LEFT_TOLD) {
          // Clear timeout after which leaving of route is being warned
          clearTimeout(this.routeLeftTimeoutId);
        }
        const oldIndex: number = this.currentNodeAndPosition.currentNode;
        const newIndex: number = currentNodeAndPosition.currentNode;
        const oldPosition: string = this.currentNodeAndPosition.position;
        const newPosition: string = currentNodeAndPosition.position;

        this.currentSegment = await this.getCurrentSegment(
          snappedLocation,
          route.geom.coordinates,
          this.currentSegment
        );

        if (oldIndex !== newIndex || oldPosition !== newPosition) {
          // Current index or position have changed since last position update, or if route has been left
          console.log(
            'Current node and/or position changed to: ' +
              JSON.stringify(currentNodeAndPosition) +
              '. Current segment number: ' +
              this.currentSegment
          );
          if (newPosition === 'on') {
            // Position has just changed from 'before' to 'on'
            console.log('Focus node ' + newIndex);
            this.preFocusNode(route, instructions[newIndex], snappedLngLat);
            if (this.state !== NavigationState.LEFT_TOLD) {
              this.focusNode(newIndex, instructions);
            }
            if (newIndex === 0) {
              console.log('Reset current segment when first navigation node is reached.');
              this.currentSegment = 0;
            }
            if (newIndex === instructions.length - 1) {
              // End navigation if last node was just played
              console.log('Last navigation node reached.');
              //await this.finishNavigation();
            }
          } else if (newPosition === 'before') {
            console.log('Prefocus node ' + newIndex);
            this.preFocusNode(route, instructions[newIndex], snappedLngLat);
          } else {
            console.error('Unknown position');
          }
        }
        // FIXME: Don't assign global varibales here, do it differently
        this.currentNodeAndPosition = currentNodeAndPosition;
        if (this.state === NavigationState.LEFT_TOLD) {
          // Route has just been reclaimed
          this.audio = UtilService.playAudio('assets/audio/route_reclaimed.m4a', this.audio);
        }
        if (
          this.state === NavigationState.LEFT ||
          this.state === NavigationState.LEFT_TOLD ||
          this.state === NavigationState.AWAITED ||
          this.state === NavigationState.OFF
        ) {
          // Switch state to active
          this.state = NavigationState.ACTIVE;
          console.log('State: ' + this.state);
        }
        const distance = NavigationService.approximateRemainingDistance(snappedLngLat, route, this.currentSegment);
        const roundedDistance = UtilService.formatDistanceString(distance);
        this.events.publish('navigation:remaining-distance', roundedDistance);
        // Update instruction with new distance
        const instruction: NavigationInstruction = instructions[currentNodeAndPosition.currentNode];
        await this.buildInstructions(instruction, currentNodeAndPosition.distance);
        this.events.publish('navigation:update-instruction-panel', instruction, this.state);
        this.events.publish('navigation:on-route');
      } else {
        // If user is away from the route add position marker to current position
        this.showOwnPosition(currentLngLat);
        if (this.state === NavigationState.OFF) {
          // Switch state to 'awaited' to prevent multiple calls of the following method
          this.state = NavigationState.AWAITED;
          this.showNavigationNode(route, this.proximityDistance, instructions[0]);
        }
        if (this.state === NavigationState.ACTIVE) {
          // Detect leaving of route
          const brooaderSnap: SnappedLocation = await this.snapToRouteV2(
            position,
            route,
            this.proximityDistance,
            this.currentSegment
          );
          if (brooaderSnap === null) {
            // Switch state to 'left', but only if a wider/broader snap with a larger radius fails
            this.state = NavigationState.LEFT;
            console.log('State: ' + this.state);
            console.warn('left route');
            this.routeLeftTimeoutId = setTimeout(async () => {
              // Only warn 20 seconds after state was set to 'left'
              this.state = NavigationState.LEFT_TOLD;
              const instruction = await firstValueFrom(this.translate.get('services.navigation.instruction-LEFT_ROUTE'));
              this.events.publish(
                'navigation:update-instruction-panel',
                new NavigationInstruction(instruction),
                this.state
              );
              this.events.publish('navigation:left-route');
              this.audio = UtilService.playAudio('assets/audio/route_left.m4a', this.audio);
              this.events.publish('navigation:remaining-distance', null);
            }, 20000);
          }
        }
      }
    }
  }

  /**
   * Show own position and orientation as an arrow.
   * The arrow is later rotated in map-component
   */
  public showOwnPosition(currentLngLat: LngLat) {
    if (!this.positionMarkerAdded) {
      // Add own posion marker for the first time
      this.mapService.map.loadImage('/assets/images/own_position_marker.png', (error, image) => {
        if (error) {
          throw error;
        }
        // ..add image to the map
        this.mapService.map.addImage('positionMarker', image);
        // ..and reference it with a coordinate
        const ownPositon = {
          id: Constants.LAYER_NAVIGATION_OWN_POSITION,
          type: 'symbol',
          source: {
            type: 'geojson',
            data: {
              type: 'FeatureCollection',
              features: [
                {
                  type: 'Feature',
                  geometry: {
                    type: 'Point',
                    coordinates: [currentLngLat.lng, currentLngLat.lat],
                  },
                },
              ],
            },
          },
          layout: {
            'icon-image': 'positionMarker',
            'icon-size': 0.5,
            'icon-rotation-alignment': 'map',
            'icon-rotate': 0,
            'icon-allow-overlap': true,
            'icon-ignore-placement': true,
          },
        };
        //this.mapService.addLayerAfter(ownPositon, undefined, Constants.LAYER_ROUTING_CUSTOM_ROUTE);
        this.mapService.addLayer(ownPositon);
      });
      this.positionMarkerAdded = true;
    } else {
      // .. or update data any subsequent time
      const source = this.mapService.map.getSource(Constants.LAYER_NAVIGATION_OWN_POSITION) as GeoJSONSource;
      if (source !== undefined) {
        source.setData({
          type: 'FeatureCollection',
          features: [
            {
              type: 'Feature',
              properties: {},
              geometry: {
                type: 'Point',
                coordinates: [currentLngLat.lng, currentLngLat.lat],
              },
            },
          ],
        });
        const rotation: number = this.positionBuffer.getRotation();
        //console.log('Rotation: ' + rotation + '; Speed: ' + this.positionBuffer.getSpeed());
        if (this.positionBuffer.getSpeed() > 0.5 || this.positionBuffer.getSpeed() === null) {
          // Only change marker rotation if last detected speed was more than 0.5 m/s (or if no speed data is availabe at all)
          this.mapService.map.setLayoutProperty(Constants.LAYER_NAVIGATION_OWN_POSITION, 'icon-rotate', rotation);
        }
      }
    }
    // Center map
    if (!this.mapService.timeoutPan) {
      this.mapService.map.setCenter(currentLngLat);
    }
  }

  /**
   * Setup the UI for navigation, e.g. generate texts for instructions.
   */
  public async setupNavigationView(route: RoutingSolution) {
    // Remove GeolocateControl to prevent disabling updates of user's location.
    this.events.publish('navigation:setup-navigation-view');
    // this.showNavigationNodes(route, 0.02);
    for (const instruction of route.instructions) {
      await this.buildInstructions(instruction);
    }
    // Disable dragging of marker
    this.mapService.markers.forEach((marker: Marker) => {
      marker.setDraggable(false);
    });
  }

  /**
   * Cancel ongoing navigation
   */
  public async cancelNavigation() {
    if (Capacitor.isNativePlatform()) {
      // Allow sleep again
      try {
        await KeepAwake.allowSleep();
        console.log('Allowed display to sleep again.');
      } catch (e) {
        console.log('Could not allow display to sleep again.');
      }

      this.positionService.stopBackgroundWatch();
    } else {
      this.positionService.clearWatch();
    }

    // Reset view
    this.events.publish('navigation:cancel-navigation-view');

    if (this.mapService.map.hasImage('positionMarker')) {
      this.mapService.map.removeImage('positionMarker');
    }
    this.mapService.removeLayer(Constants.LAYER_NAVIGATION_ARROW_LINES);
    this.mapService.removeLayer(Constants.LAYER_NAVIGATION_ARROW_HEADS);
    // Reset instruction node index
    this.currentNodeAndPosition = { currentNode: 0, position: 'before', distance: undefined };
    // Reset current segment
    this.currentSegment = 0;
    // Remove own position with rotated marker
    this.positionMarkerAdded = false;
    this.mapService.removeLayer(Constants.LAYER_NAVIGATION_OWN_POSITION);
    // Reset navigation state to 'off'
    if (this.state !== NavigationState.OFF) {
      this.state = NavigationState.OFF;
      console.log('State: ' + this.state);
      this.events.publish('navigation:update-instruction-panel', null, this.state);
    }
    // Disable dragging of marker
    this.mapService.markers.forEach((marker: Marker) => {
      marker.setDraggable(true);
    });
  }


  /**
   * Draw a single navigation node
   *
   * @param route
   * @param size - The length of the buffer in each direction of the point
   * @param instruction
   */
  public showNavigationNode(route: RoutingSolution, size: number, instruction: NavigationInstruction): void {
    const routeGeometry: LineString = route.geom as LineString;
    const navigationNodeLocation = instruction.point;
    const navigationNodeBuffer: turf.helpers.Feature<LineString> = UtilService.lineBufferV3(
      routeGeometry,
      navigationNodeLocation,
      size,
      this.currentSegment
    );
    const navigationNodeBufferFeatures: turf.helpers.Feature<LineString>[] = [];
    navigationNodeBufferFeatures.push(navigationNodeBuffer);
    const navigationNodeBufferFeatureCollection = turf.featureCollection(navigationNodeBufferFeatures);

    this.mapService.removeLayer(Constants.LAYER_NAVIGATION_ARROW_LINES);

    // Create the line part of the arrow
    const lines = {
      id: Constants.LAYER_NAVIGATION_ARROW_LINES,
      type: 'line',
      source: {
        type: 'geojson',
        data: navigationNodeBufferFeatureCollection,
      },
      layout: {
        'line-join': 'round',
        'line-cap': 'round',
      },
      paint: {
        'line-color': '#ffff00',
        'line-opacity': 1.0,
        'line-width': 5,
      },
    };
    this.mapService.addLayerAfter(lines, undefined, Constants.LAYER_ROUTING_DIRECTION);

    // Collect the points where the arrow heads are directing
    const directedPointFeatures: turf.helpers.Feature<Point>[] = [];

    for (const bufferFeature of navigationNodeBufferFeatures) {
      // The directed point is the last point of the line string
      const lineString: LineString = bufferFeature.geometry as LineString;
      const directedPointCoordinates: number[] = lineString.coordinates[lineString.coordinates.length - 1];
      const directedPointFeature = turf.point(directedPointCoordinates);
      // The bearing is the angle between the last point and the next to last point
      if (lineString.coordinates.length >= 2) {
        const nextToLastPointCoordinates: number[] = lineString.coordinates[lineString.coordinates.length - 2];
        directedPointFeature.properties.bearing = turf.bearing(nextToLastPointCoordinates, directedPointCoordinates);
        directedPointFeature.properties.current = false;
        directedPointFeatures.push(directedPointFeature);
      }
    }
    const directedPointsFeatureCollection = turf.featureCollection(directedPointFeatures);

    this.mapService.map.loadImage('/assets/images/navigation_node.png', (error, image) => {
      if (error) {
        throw error;
      }
      // (Remove previously added image and layer)
      if (this.mapService.map.hasImage('arrowHead')) {
        this.mapService.map.removeImage('arrowHead');
      }
      this.mapService.removeLayer(Constants.LAYER_NAVIGATION_ARROW_HEADS);

      // ..add image to the map
      this.mapService.map.addImage('arrowHead', image);
      // ..and reference it with a coordinate
      const arrowHeads = {
        id: Constants.LAYER_NAVIGATION_ARROW_HEADS,
        type: 'symbol',
        source: {
          type: 'geojson',
          data: directedPointsFeatureCollection,
        },
        layout: {
          'icon-image': 'arrowHead',
          'icon-size': 0.5,
          'icon-rotation-alignment': 'map',
          'icon-rotate': {
            type: 'identity',
            property: 'bearing',
            default: 90,
          },
        },
      };
      this.mapService.addLayerAfter(arrowHeads, undefined, Constants.LAYER_NAVIGATION_ARROW_LINES);
      //this.mapService.addLayer(arrowHeads);
    });
  }

  /**
   * The instruction panel is updated when the next navigation node is in range.
   */
  public preFocusNode(route: RoutingSolution, instruction: NavigationInstruction, snappedLngLat: LngLat) {
    this.showNavigationNode(route, this.proximityDistance, instruction);
    // Tells the routing page to reflect changes in current navigation instruction
    this.events.publish('navigation:update-instruction-panel', instruction, this.state);
    // Align map so that the node is up
    const snappedLngLatCoordinates: number[] = snappedLngLat.toArray();
    const instructionCoordinates: number[] = instruction.point.coordinates;
    const bearing = turf.bearing(snappedLngLatCoordinates, instructionCoordinates);
    // Change bearing of map if instruction point is far enough from snapped position
    const distance = turf.distance(snappedLngLatCoordinates, instructionCoordinates, { units: 'meters' });
    if (distance > this.proximityDistance) {
      this.mapService.map.flyTo({ bearing });
      // Re-enable automatic centering
      this.mapService.cancelPanTimeout();
    }
  }

  /**
   * An audio is played when the navigation node is actually reached.
   */
  public focusNode(index: number, instructions: NavigationInstruction[]) {
    if (instructions[index].audioFile !== undefined) {
      this.audio = UtilService.playAudio(instructions[index].audioFile, this.audio);
    } else {
      this.audio = UtilService.playAudio('assets/audio/notification.mp3', this.audio);
    }
  }

  /**
   * Shows an alert message to inform the user that the end of the tour has been reached,
   * then publishes an event to notify the routing page.
   */
  async finishNavigation() {
    const header = await firstValueFrom(this.translate.get('services.navigation.finished-header'));
    const message = await firstValueFrom(this.translate.get('services.navigation.finished-message'));
    if (this.state !== NavigationState.OFF) {
      this.state = NavigationState.OFF;
      const alert = await this.alertCtrl.create({
        header,
        message,
        buttons: [
          {
            text: 'OK',
            handler: () => {
              this.events.publish('navigation:finished');
            },
          },
        ],
      });
      await alert.present();
    }
  }

  /**
   * Find the nearest position on the route seen from the user's real position
   * and snap to that position if it is near enough.
   * Mind the current segment to deal with partly overlapping segments.
   */
  private async snapToRouteV2(
    realPosition: Position,
    route: RoutingSolution,
    radius: number,
    currentSegmentNr: number
  ): Promise<SnappedLocation> {
    const routeCoords = route.geom.coordinates;
    const point = turf.point([realPosition.coords.longitude, realPosition.coords.latitude]);
    // Initialize margins
    let lowerMargin: number = currentSegmentNr;
    let upperMargin: number = currentSegmentNr;
    const segmentCoordinates: number[][] = routeCoords.slice(currentSegmentNr, currentSegmentNr + 2);
    const segment = turf.lineString(segmentCoordinates, { name: 'line segment' }).geometry;
    // Try to snap on current segment
    let snappedLocation: SnappedLocation = null;
    const snappedPointFeature: turf.helpers.Feature<Point> = turf.nearestPointOnLine(segment, point, {
      units: 'meters',
    });
    if (snappedPointFeature.properties.dist < radius) {
      snappedLocation = new SnappedLocation(snappedPointFeature.geometry);
      return snappedLocation;
    }
    // Could not snap on current segment
    while (lowerMargin > 0 || upperMargin < routeCoords.length - 2) {
      // Search to the left
      // Decrement left margin segment if possible
      if (lowerMargin > 0) {
        lowerMargin--;
        snappedLocation = await this.snapToSegmentOfRoute(routeCoords, lowerMargin, point, radius);
      }
      if (upperMargin < routeCoords.length - 2 && snappedLocation === null) {
        // Search to the right
        // Increment right margin segment if possible
        upperMargin++;
        snappedLocation = await this.snapToSegmentOfRoute(routeCoords, upperMargin, point, radius);
      }
      if (snappedLocation !== null) {
        return snappedLocation;
      }
    }
    // Could not snap anywhere
    return null;
  }

  /**
   * Find the nearest position on the route seen from the user's real position
   * and snap to that position if it is near enough.
   * Do not mind the current segment and therefor do not deal with partly overlapping segments.
   */
  private snapToRoute(realPosition: Position, route: RoutingSolution, radius: number): SnappedLocation {
    const line: LineString = route.geom;
    // const lineFeature: turf.helpers.Feature<LineString> = turf.lineString(line.coordinates);
    const point = turf.point([realPosition.coords.longitude, realPosition.coords.latitude]);
    const snappedPointFeature: turf.helpers.Feature<Point> = turf.nearestPointOnLine(line, point, { units: 'meters' });
    console.log('Distance to route: ' + snappedPointFeature.properties.dist);
    if (snappedPointFeature.properties.dist < radius) {
      const snappedPoint: Point = snappedPointFeature.geometry;
      if (UtilService.pointOnLine(snappedPoint, line)) {
        return new SnappedLocation(snappedPoint);
      } else {
        throw Error('Snapped point not located on route. This should never happen.');
      }
    } else {
      return null;
    }
  }

  /**
   * Takes a number in the coordinates array of a route and tries to snap a given point to a segment
   * defined by this coordinates pair and the next coordinates pair.
   */
  private async snapToSegmentOfRoute(
    routeCoords: GeoJSON.Position[],
    arrayPosition: number,
    point: turf.helpers.Feature<turf.helpers.Point>,
    radius: number
  ): Promise<SnappedLocation> {
    const segmentCoordinates: GeoJSON.Position[] = routeCoords.slice(arrayPosition, arrayPosition + 2);
    const segment = turf.lineString(segmentCoordinates, { name: 'line segment' }).geometry;
    // Try to snap on segment
    const snappedPointFeature: turf.helpers.Feature<Point> = turf.nearestPointOnLine(segment, point, {
      units: 'meters',
    });
    if (snappedPointFeature.properties.dist < radius) {
      return new SnappedLocation(snappedPointFeature.geometry);
    } else {
      return null;
    }
  }

  /**
   * Find the nearest instruction to a snapped position.
   *
   * Therefore compare the distances from the snapped position to all instruction nodes. Start search at a given instruction node and search until the end of the array of instruction nodes is reached.
   *
   * @param snappedLngLat - The snapped position on the route
   * @param route - The route
   * @param startNode - An index of the array of instructions
   */
  private getNearestInstructionOnRouteWithStartNode(
    snappedLngLat: LngLat,
    route: RoutingSolution,
    startNode: number
  ): number {
    const routeFeature: turf.helpers.Feature<LineString> = turf.lineString(route.geom.coordinates);
    const snappedPoint = turf.point([snappedLngLat.lng, snappedLngLat.lat]).geometry;
    if (UtilService.pointOnLine(snappedPoint, routeFeature.geometry)) {
      let nextInstructionIndex = 0;
      let minDistance = Infinity;
      const instructions = route.instructions;
      // const currentNode: number = this.currentNodeAndPosition.currentNode;
      for (let i = startNode; i < instructions.length; i++) {
        const positionToInstructionDist: number = Math.abs(
          UtilService.distanceOnLineV2(snappedPoint, instructions[i].point, route.geom, 0)
        );
        if (positionToInstructionDist < minDistance) {
          minDistance = positionToInstructionDist;
          nextInstructionIndex = i;
        }
      }
      return nextInstructionIndex;
    } else {
      console.log('Snapped point not located on route. This should never happen.');
    }
  }

  /**
   *
   * Find the nearest instruction to a snapped position.
   * Therefore compare the distances from the snapped position to all instruction nodes. Start search at a given instruction node and search until the end of the array of instruction nodes is reached.
   *
   * @param snappedLngLat - The snapped position on the route
   * @param route - The route
   * @param startSegment - The current segment
   * @param startNode The node to start search from to prevent stepping back when reclaiming route
   */
  private getNearestInstructionOnRouteWithStartSegmentAndNode(
    snappedLngLat: LngLat,
    route: RoutingSolution,
    startSegment: number,
    startNode: number
  ): number {
    const routeFeature: turf.helpers.Feature<LineString> = turf.lineString(route.geom.coordinates);
    const snappedPoint = turf.point([snappedLngLat.lng, snappedLngLat.lat]).geometry;
    if (UtilService.pointOnLine(snappedPoint, routeFeature.geometry)) {
      let nextInstructionIndex = 0;
      let minDistance = Infinity;
      const instructions = route.instructions;
      for (let i = startNode; i < instructions.length; i++) {
        console.log('In getNearestInstructionOnRouteWithStartSegmentAndNode, will call distanceOnLineV2', i);
        const positionToInstructionDist: number = Math.abs(
          UtilService.distanceOnLineV2(snappedPoint, instructions[i].point, route.geom, startSegment)
        );
        if (positionToInstructionDist < minDistance) {
          minDistance = positionToInstructionDist;
          nextInstructionIndex = i;
        }
      }
      return nextInstructionIndex;
    } else {
      console.log('Snapped point not located on route. This should never happen.');
    }
  }

  /**
   * Determines the next instruction on route and the relative position of the snapped location to this instruction
   * ('before' | 'on')
   */
  private getCurrentNodeAndPositionV2(snappedLngLat: LngLat, route: RoutingSolution, startSegment: number, speed: number) {
    let nearestInstruction: number = this.currentNodeAndPosition.currentNode;
    let position: string;
    let displayDistance: number;
    if (this.state === NavigationState.AWAITED) {
      // Only reset nearest node if navigation hasn't started yet.
      nearestInstruction = this.getNearestInstructionOnRouteWithStartSegmentAndNode(
        snappedLngLat,
        route,
        startSegment,
        nearestInstruction
      );
    }
    // Most importantly the decision do to keep or to increment nearestInstruction
    const snappedPoint = turf.point([snappedLngLat.lng, snappedLngLat.lat]);
    const distSnappedPointInstruction = UtilService.distanceOnLineV2(
      snappedPoint.geometry,
      route.instructions[nearestInstruction].point,
      route.geom,
      startSegment
    );
    if (distSnappedPointInstruction !== undefined) {
      const adaptiveDistance: number = this.adaptiveProximityDistance(this.proximityDistance, speed)
      console.log("Current speed is " + speed + ". Adaptive distance is " + adaptiveDistance)

      if (distSnappedPointInstruction >= 0 && distSnappedPointInstruction < adaptiveDistance) {
        // Snapped position lies before and on nearest instruction
        position = 'on';
        displayDistance = 0;
      } else if (distSnappedPointInstruction >= 0) {
        // Snapped position lies before nearest instruction
        position = 'before';
        // next node == nearest node, only round to the nearest 10 metres
        displayDistance = Math.abs(Math.round(distSnappedPointInstruction / 10) * 10);
      } else if (distSnappedPointInstruction < 0 && nearestInstruction < route.instructions.length - 1) {
        // Snapped position lies after nearest instruction
        // next node == nearest node + 1, calculate distance to next node
        position = 'before';
        displayDistance = NavigationService.roundedDistanceToNextNode(
          snappedPoint.geometry,
          route.instructions[nearestInstruction + 1].point,
          route.geom
        );
        nearestInstruction++;
      } else if (nearestInstruction === route.instructions.length - 1) {
        console.log('You were detected: ' + Math.abs(distSnappedPointInstruction) + ' after the last node.');
      } else {
        console.log(
          'Cannot determine next currentNodeAndPosition; distSnappedPointInstruction: ' + distSnappedPointInstruction
        );
      }
    }
    return {
      currentNode: nearestInstruction,
      position,
      distance: displayDistance,
    };
  }

  /**
   * Return the number of the current segment
   *
   * @param snappedLocation
   * @param routeCoords
   * @param oldSegmentNumber
   */
  private async getCurrentSegment(snappedLocation: SnappedLocation, routeCoords: number[][], oldSegmentNumber: number) {
    for (let i = oldSegmentNumber; i < routeCoords.length - 1; i++) {
      const segment: number = UtilService.findPointOnRouteSegment(snappedLocation.point, routeCoords, i);
      if (segment !== null) {
        return segment;
      }
    }
    // return old segment number if not found
    return oldSegmentNumber;
  }

  /**
   * For round trips: Snap to route, determine nearest instruction node on the route and
   * rearrange list of instruction nodes insofar as the new route will start and end at the nearest node.
   * @param position
   * @param route
   * @returns
   */
  public rearrangeInstructions(position: Position, route: RoutingSolution): RoutingSolution {
    const snappedLocation: SnappedLocation = this.snapToRoute(position, route, Infinity);
    const snappedLngLat: LngLat = snappedLocation.lngLat;
    const nearestInstruction: number = this.getNearestInstructionOnRouteWithStartNode(snappedLngLat, route, 0);
    const rearrangedRoute = this.rewriteRoute(route, nearestInstruction);
    return rearrangedRoute;
  }

  /**
   * Rewrite the route
   *
   */

  /**
   * Rearrange array of instruction nodes insofar as the new route will start and end at the nearest node.
   * @param route
   * @param nearestInstruction
   * @returns The route with rearranged instructions
   */
  private rewriteRoute(route: RoutingSolution, nearestInstruction: number): RoutingSolution {
    const instructions = [...route.instructions];
    // Safety check on integrity of the instructions array
    if (instructions[nearestInstruction].number == nearestInstruction) {
      const rearrangedInstructions: NavigationInstruction[] = UtilService.rearrangeArray(
        instructions,
        nearestInstruction
      );
      route.instructions = rearrangedInstructions;
      return route;
    }
    // nothing modified
    return route;
  }

  /**
   * Calculates a distance to a navigation node at which one is detected as 'on' node.
   * @param standardDistance - The distance at which there is no adaptation and 1 is returned
   * @param speed - The current speed in meters per second.
   * The bigger the spped is, the bigger the distance must be.
   */
  adaptiveProximityDistance(standardDistance: number, speed: number): number {
    const weight: number = speed / 4.1666665 // 15 km/h
    return weight * standardDistance
  }
}
