import {APP_INITIALIZER, Injector, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {RouteReuseStrategy} from '@angular/router';
import {OAuthModule, OAuthService, OAuthStorage} from 'angular-oauth2-oidc';

import {IonicStorageModule} from '@ionic/storage-angular';
import {IonicModule, IonicRouteStrategy, isPlatform} from '@ionic/angular';

import {AppComponent} from './app.component';
import {AppRoutingModule} from './app-routing.module';

import {BusStopService} from './services/busstop.service';
import {ConnectionService} from './services/connection.service';
import {DataService} from './services/data.service';
import {GeocodingService} from './services/geocoding.service';
import {GlobalsService} from './services/globals.service';
import {LayerService} from './services/layer.service';
import {LinksService} from './services/links.service';
import {MapService} from './services/map.service';
import {NavigationService} from './services/navigation.service';
import {NotificationService} from './services/notification.service';
import {PoisService} from './services/pois.service';
import {PositionService} from './services/position.service';
import {RoutingService} from './services/routing.service';
import {SearchService} from './services/search.service';
import {SettingsService} from './services/settings.service';
import {SocialService} from './services/social.service';
import {ToursService} from './services/tours.service';
import {UserService} from './services/user.service';
import {UtilService} from './services/util.service';

import {TranslateLoader, TranslateModule, TranslateService} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {HttpClient, HttpClientModule} from '@angular/common/http';

import {ListPopoverComponentModule} from './components/list-popover/list-popover.module';
import {ToolbarSearchbarComponentModule} from './components/toolbar-searchbar/toolbar-searchbar.module';
import {MapComponentModule} from './components/map/map.module';
import {LinkPopoverComponentModule} from './components/link-popover/link-popover.module';
import {MapMenuComponentModule} from './components/map-menu/map-menu.module';
import {NgxFileHelpersModule} from 'ngx-file-helpers';
import {ScrollbarThemeModule} from './directives/scrollbar-theme.module';
import {LOCATION_INITIALIZED} from '@angular/common';
import {MatomoConsentMode, NgxMatomoTrackerModule} from '@ngx-matomo/tracker';
import {NgxMatomoRouterModule} from '@ngx-matomo/router';
import {DarkModeSwitchComponentModule} from './components/dark-mode-switch/dark-mode-switch.module';
import {ServiceWorkerModule} from '@angular/service-worker';
import {environment} from '../environments/environment';

import {SwiperModule} from 'swiper/angular';
import {FacebookLoginProvider, SocialAuthServiceConfig, SocialLoginModule} from '@abacritt/angularx-social-login';

const getConfig = () => isPlatform('ios') ? {
  backButtonText: 'Zurück',
  innerHTMLTemplatesEnabled: true
} : {
  innerHTMLTemplatesEnabled: true
};

// IMPORTANT AoT requires an exported function for factories
export const createTranslateLoader = (http: HttpClient) => new TranslateHttpLoader(http, '../assets/i18n/', '.json');

export const appInitializerFactory = (translate: TranslateService, injector: Injector) =>
  () => new Promise<any>((resolve: any) => {
    const locationInitialized = injector.get(LOCATION_INITIALIZED, Promise.resolve(null));
    locationInitialized.then(() => {
      const langToSet = 'de';
      translate.setDefaultLang('de');
      translate.use(langToSet).subscribe({
        next: () => {
          console.log(`Successfully initialized '${langToSet}' language.'`);
        },
        error: () => {
          console.error(`Problem with '${langToSet}' language initialization.'`);
        },
        complete: () => {
          resolve(null);
        }
      });
    });
  });

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    IonicModule.forRoot(getConfig()),
    IonicStorageModule.forRoot(),
    AppRoutingModule,
    OAuthModule.forRoot(),
    SocialLoginModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
    ListPopoverComponentModule,
    ToolbarSearchbarComponentModule,
    MapComponentModule,
    LinkPopoverComponentModule,
    MapMenuComponentModule,
    NgxFileHelpersModule,
    ScrollbarThemeModule,
    NgxMatomoTrackerModule.forRoot({
      siteId: 9,
      trackerUrl: 'https://stats.apps.eftas.com/matomo.php',
      scriptUrl: 'https://stats.apps.eftas.com/matomo.js',
      requireConsent: MatomoConsentMode.COOKIE
    }),
    NgxMatomoRouterModule,
    DarkModeSwitchComponentModule,
    ServiceWorkerModule.register('ngsw-worker.js', {enabled: environment.production}),
    SwiperModule
  ],
  providers: [
    BusStopService,
    ConnectionService,
    DataService,
    GeocodingService,
    GlobalsService,
    LayerService,
    LinksService,
    MapService,
    NavigationService,
    NavigationService,
    NotificationService,
    OAuthService,
    PoisService,
    PositionService,
    RoutingService,
    SearchService,
    SettingsService,
    SocialService,
    ToursService,
    UserService,
    UtilService,
    {
      provide: APP_INITIALIZER,
      useFactory: (appInitializerFactory),
      deps: [TranslateService, Injector],
      multi: true
    },
    {provide: RouteReuseStrategy, useClass: IonicRouteStrategy},
    {provide: OAuthStorage, useValue: localStorage},
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider('552626831832660')
          }
        ]
      } as SocialAuthServiceConfig,
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
