import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {AlertController, ToastController} from '@ionic/angular';
import {MatomoTracker} from '@ngx-matomo/tracker';
import {firstValueFrom} from "rxjs";
import {async} from "@angular/core/testing";

@Injectable({
  providedIn: 'root'
})
export class TrackingService {

  constructor(
    private alertCtrl: AlertController,
    private matomoTracker: MatomoTracker,
    private toastCtrl: ToastController,
    private translate: TranslateService
  ) {
  }

  async isDNTEnabled(): Promise<boolean> {
    /* Test if Do-Not-Track is enabled. */
    const dnt = (((window as any).doNotTrack && (window as any).doNotTrack === '1') ||
      (navigator.doNotTrack && (navigator.doNotTrack === 'yes' || navigator.doNotTrack === '1')));
    if (dnt) {
      const dntFoundToast = await firstValueFrom(this.translate.get('app.cookie-preferences.dnt-toast'));
      let cssClass = 'dnt-toast';
      if (window.innerWidth >= 1280 && window.innerHeight < 830 || window.innerHeight < 730) {
        cssClass += ' slim-header';
      }
      const toast = await this.toastCtrl.create({
        message: dntFoundToast,
        duration: 3000,
        position: 'top',
        cssClass
      });
      await toast.present();
    }
    return dnt;
  }

  async askForCookieConsent(): Promise<boolean> {
    const header: string = await firstValueFrom(this.translate.get('app.cookie-preferences.header'));
    const message: string = await firstValueFrom(this.translate.get('app.cookie-preferences.message'));
    const btnAgree: string = await firstValueFrom(this.translate.get('shared.agree'));
    const btnDecline: string = await firstValueFrom(this.translate.get('shared.decline'));

    const alert = await this.alertCtrl.create({
      header,
      message,
      cssClass: 'cookie-dialog',
      backdropDismiss: false,
      buttons: [{
        text: btnDecline,
        role: 'deny'
      }, {
        text: btnAgree,
        role: 'consent'
      }]
    });
    await alert.present();
    const detail = await alert.onDidDismiss();
    return detail.role === 'consent';
  }

  async setTracking(trackingConsent: boolean, cookieConsent?: boolean) {
    if (trackingConsent) {
      // init matomo
      this.matomoTracker.rememberConsentGiven();
      if (cookieConsent) {
        // enable cookies use
        this.matomoTracker.rememberCookieConsentGiven();
      } else {
        // disable cookie use
        this.matomoTracker.forgetCookieConsentGiven();
      }
    } else {
      // no matomo :-(
      this.matomoTracker.forgetConsentGiven();
      // disable cookie use
      this.matomoTracker.forgetCookieConsentGiven();
    }
  }

  trackPageView(customTitle?: string) {
    this.matomoTracker.trackPageView(customTitle);
  }

  trackEvent(category: string, action: string, name?: string, value?: number) {
    this.matomoTracker.trackEvent(category, action, name, value);
  }
}
