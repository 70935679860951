import {Injectable} from '@angular/core';
import {StorageService} from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {

  settings: { key: string; value: any }[];

  constructor(
    private storage: StorageService
  ) {
  }

  async get(key: string): Promise<any> {
    if (!this.settings) {
      await this.restore();
    }
    const setting = this.settings.find(entry => entry.key === key);
    return setting?.value;
  }

  async set(key: string, value: any, overwrite: boolean = true) {
    if (!this.settings) {
      await this.restore();
    }
    const index = this.settings.findIndex(entry => entry.key === key);
    if (index === -1) {
      this.settings.push({key, value});
      await this.save();
    } else if (overwrite) {
      this.settings[index].value = value;
      await this.save();
    }
  }

  async remove(key: string) {
    if (!this.settings) {
      await this.restore();
    }
    const index = this.settings.findIndex(entry => entry.key === key);
    if (index !== -1) {
      this.settings.splice(index, 1);
      await this.save();
    }
  }

  async clear() {
    this.settings = [];
    await this.save();
  }

  private async restore() {
    const keys = await this.storage.keys();
    if (keys.indexOf('user-settings') !== -1) {
      this.settings = await this.storage.get('user-settings');
    } else {
      this.settings = [];
    }
  }

  private async save() {
    await this.storage.set('user-settings', this.settings);
  }
}
