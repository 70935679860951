import {Component, Input, OnInit} from '@angular/core';
import {Storage} from '@ionic/storage-angular';
import {EventsService} from '../../services/events.service';

@Component({
  selector: 'app-dark-mode-switch',
  templateUrl: './dark-mode-switch.component.html',
  styleUrls: ['./dark-mode-switch.component.scss'],
})
export class DarkModeSwitchComponent implements OnInit {

  @Input() background: 'light' | 'dark' = 'light';
  @Input() size = 'normal';

  isDarkMode: boolean;

  constructor(
    private events: EventsService,
    private storage: Storage
  ) {
  }

  async ngOnInit() {
    let colorMode = await this.storage.get('colorMode');
    console.log('stored color mode: ', colorMode);
    if (!colorMode) {
      if (window.matchMedia) {
        colorMode = window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light';
      } else {
        colorMode = 'light';
      }
    }
    await this.setColorMode(colorMode === 'dark');
  }

  async setColorMode(isDarkMode: boolean) {
    this.isDarkMode = isDarkMode;
    const colorMode = isDarkMode ? 'dark' : 'light';
    await this.storage.set('colorMode', colorMode);
    if (isDarkMode) {
      if (!document.body.classList.contains('dark')) {
        document.body.classList.add('dark');
      }
    } else {
      document.body.classList.remove('dark');
    }
    document.documentElement.setAttribute('data-theme', colorMode);
  }
}
