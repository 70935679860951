import {Directive, ElementRef} from '@angular/core';
import {Capacitor} from '@capacitor/core';

@Directive({
  selector: '[appScrollbarTheme]'
})
export class ScrollbarThemeDirective {
  constructor(el: ElementRef) {
    if (!Capacitor.isNativePlatform() && window.innerWidth > window.innerHeight) {
      const stylesheet = `
              /* Works on Firefox */
              * {
                scrollbar-width: thin;
                scrollbar-color: #aaaaaa #ebebeb;
              }

              /* Works on Chrome, Edge, and Safari */
              *::-webkit-scrollbar {
                width: 10px;
              }
              *::-webkit-scrollbar-track {
                background: #ebebeb;
              }
              *::-webkit-scrollbar-thumb {
                border-radius: 1rem;
                background: #aaaaaa;
              }
              *::-webkit-scrollbar-thumb:hover {
                background: #999999;
              }
            `;

      const styleElmt = el.nativeElement.shadowRoot.querySelector('style');

      if (styleElmt) {
        styleElmt.append(stylesheet);
      } else {
        const barStyle = document.createElement('style');
        barStyle.append(stylesheet);
        el.nativeElement.shadowRoot.appendChild(barStyle);
      }
    }
  }
}
