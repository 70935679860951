import {Component, OnInit} from '@angular/core';
import {Item} from '../../lib/types/radrevier-ruhr';
import {NavParams, Platform, PopoverController} from '@ionic/angular';

@Component({
  selector: 'app-share-popover',
  templateUrl: './share-popover.component.html',
  styleUrls: ['./share-popover.component.scss'],
})
export class SharePopoverComponent implements OnInit {
  item: Item;
  mobile: boolean;

  constructor(
    private navParams: NavParams,
    private platform: Platform,
    private popoverCtrl: PopoverController
  ) {
  }

  ngOnInit() {
    this.item = this.navParams.get('item');
    // retrieve platform
    this.mobile = this.platform.is('mobile');
  }

  async dismiss(service: string) {
    await this.popoverCtrl.dismiss({service});
  }
}
