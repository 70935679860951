// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  overflow-y: auto;
}

.selected {
  --background: rgba(0, 0, 0, 0.1);
}

[text-wrap] .label-md {
  font-size: 16px;
  line-height: 1.4;
}`, "",{"version":3,"sources":["webpack://./src/app/components/map-menu/map-menu.component.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;AACF;;AAEA;EACE,gCAAA;AACF;;AAEA;EACE,eAAA;EACA,gBAAA;AACF","sourcesContent":[":host {\n  overflow-y: auto;\n}\n\n.selected {\n  --background: rgba(0, 0, 0, 0.1);\n}\n\n[text-wrap] .label-md {\n  font-size: 16px;\n  line-height: 1.4;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
