// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-toggle {
  --background-checked: rgba(var(--ion-text-color-rgb, 0, 0, 0), 0.39);
  --handle-background-checked: #fff;
}

.bg-dark ion-button ion-icon {
  color: var(--ion-color-primary-contrast);
}

.bg-light ion-button ion-icon {
  color: var(--ion-color-primary);
}
.bg-light ion-toggle {
  --background: var(--ion-color-medium-tint);
  --background-checked: var(--ion-color-medium-tint);
  --handle-background: var(--ion-color-primary);
  --handle-background-checked: var(--ion-color-primary);
}

.dark-mode-switch ion-button:first-child {
  --padding-end: 0;
}
.dark-mode-switch ion-button:last-child {
  --padding-start: 0;
}

.icon-small {
  font-size: 16px !important;
}

.icon-normal {
  font-size: 24px !important;
}

.icon-large {
  font-size: 32px !important;
}`, "",{"version":3,"sources":["webpack://./src/app/components/dark-mode-switch/dark-mode-switch.component.scss"],"names":[],"mappings":"AAAA;EACE,oEAAA;EACA,iCAAA;AACF;;AAGE;EACE,wCAAA;AAAJ;;AAKE;EACE,+BAAA;AAFJ;AAKE;EACE,0CAAA;EACA,kDAAA;EACA,6CAAA;EACA,qDAAA;AAHJ;;AASE;EACE,gBAAA;AANJ;AASE;EACE,kBAAA;AAPJ;;AAWA;EACE,0BAAA;AARF;;AAWA;EACE,0BAAA;AARF;;AAWA;EACE,0BAAA;AARF","sourcesContent":["ion-toggle {\n  --background-checked: rgba(var(--ion-text-color-rgb, 0, 0, 0), 0.39);\n  --handle-background-checked: #fff;\n}\n\n.bg-dark {\n  ion-button ion-icon {\n    color: var(--ion-color-primary-contrast);\n  }\n}\n\n.bg-light {\n  ion-button ion-icon {\n    color: var(--ion-color-primary);\n  }\n\n  ion-toggle {\n    --background: var(--ion-color-medium-tint);\n    --background-checked: var(--ion-color-medium-tint);\n    --handle-background: var(--ion-color-primary);\n    --handle-background-checked: var(--ion-color-primary);\n  }\n}\n\n\n.dark-mode-switch {\n  ion-button:first-child {\n    --padding-end: 0;\n  }\n\n  ion-button:last-child {\n    --padding-start: 0;\n  }\n}\n\n.icon-small {\n  font-size: 16px !important;\n}\n\n.icon-normal {\n  font-size: 24px !important;\n}\n\n.icon-large {\n  font-size: 32px !important;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
