import {Injectable} from '@angular/core';
import {Platform} from '@ionic/angular';
import {EventsService} from './events.service';
import {GlobalsService} from './globals.service';
import {HttpClient} from '@angular/common/http';
import {UtilService} from './util.service';
import {
    AddLayerObject,
    GeoJSONSource,
    IControl, LayerSpecification,
    LngLat,
    LngLatBounds,
    LngLatBoundsLike,
    LngLatLike,
    Map,
    MapMouseEvent,
    Marker,
    PaddingOptions, StyleSpecification
} from 'maplibre-gl';
import {Constants} from '../var/constants';
import {BusStop, Poi, Tour} from '../lib/types/radrevier-ruhr';
import {Feature, GeoJsonProperties, Point} from 'geojson';
import {firstValueFrom, Subscription} from 'rxjs';
import {RoutingPoint} from './routing.service';
import * as turf from '@turf/turf';

export class MarkerOptions {
    draggable?: boolean;
    featureProperties?: any;
    clickHandler?: any;
    thisArg?: any;
    type: string;
    zoomTo?: boolean;
}

@Injectable({
    providedIn: 'root'
})
export class MapService {

    activeBaseLayer: any = null;
    busStopLayer: any = null;
    map: Map;
    markers: Marker[] = [];
    routingMarkers: Marker[] = [];
    infoMarker: Marker;
    hoverMarker: Marker;
    targetMarker: Marker;
    layers: { layer: any; before?: string }[] = [];
    mobile: boolean;

    poiCodes: number[] = [95, 96, 97, 99, 381262, 381263, 381274, 381275, 381276, 381277, 381278, 381279, 381280, 381281,
        381282, 381283, 381284, 381285, 381286, 381287];

    markerImages: string[] = ['95', '96', '97', '98', '98_1', '98_2', '98_3', '98_4', '98_6', '98_8', '99', '381262',
        '381263', '381274', '381275', '381276', '381277', '381278', '381279', '381280', '381281', '381282', '381283',
        '381284', '381285', '381286', '381287', 'cluster-busStops', 'cluster-nodes', 'cluster-pois'];

    dragTimeout = true;
    clickListeners = {};

    timeoutPan: any;
    private subscriptions: any = {};

    private _isReady = false;

    constructor(
        private events: EventsService,
        private globals: GlobalsService,
        private http: HttpClient,
        private platform: Platform,
        private util: UtilService
    ) {
        this.mobile = platform.is('mobile') && !platform.is('tablet');
    }

    public isReady(): boolean {
        return this._isReady;
    }

    async addVectorLayers() {
        if (this.map) {
            const mapStyle = await firstValueFrom(this.http.get(Constants.URL_MAPTILES_OPENMAPTILES)) as any;
            for (let i = mapStyle.layers.length - 1; i >= 0; i--) {
                if (i === mapStyle.layers.length - 1) {
                    this.map.addLayer(mapStyle.layers[i], 'baselayersbefore');
                } else {
                    this.map.addLayer(mapStyle.layers[i], mapStyle.layers[i + 1].id);
                }
            }
        }
    }

    async rmVectorLayers() {
        if (this.map) {
            const mapStyle = await firstValueFrom(this.http.get(Constants.URL_MAPTILES_OPENMAPTILES)) as any;
            mapStyle.layers.forEach(layer => {
                if (this.map && this.map.getLayer(layer.id)) {
                    this.map.removeLayer(layer.id);
                }
            });
        }
    }

    cancelPanTimeout() {
        if (this.timeoutPan) {
            clearTimeout(this.timeoutPan);
            this.timeoutPan = null;
        }
    }

    /**
     * Removes all features from the map.
     */
    clear(includeRoutingMarkers: boolean = true) {
        // remove pois
        this.clearMarkers(includeRoutingMarkers);
        // remove layers
        if (this.layers && this.layers.length >= 1) {
            this.layers.forEach(entry => {
                if (this.map.getLayer(entry.layer.id)) {
                    this.map.removeLayer(entry.layer.id);
                }
                if (this.map.getSource(entry.layer.id)) {
                    this.map.removeSource(entry.layer.id);
                }
            });
            this.layers = [];
        }
        this.rmPoiClusterLayers(['pois', 'nodes', 'busStops', 'selected-poi']);
    }

    async zoomToPointRadius(center, radius) {
        const circle = turf.circle(center, radius, {steps: 16, units: 'meters'});
        const bounds = turf.bbox(circle);

        await this.fitBounds([[bounds[0], bounds[1]], [bounds[2], bounds[3]]], 50);
    }

    deleteMap() {
        if (this.map) {
            this.clear();
            this.map.remove();
            delete this.map;
            this.events.publish('map:destroy');
        }
    }

    getBbox(): LngLatBounds {
        if (this.map) {
            return this.map.getBounds();
        } else {
            return new LngLatBounds([Constants.MIN_LNG, Constants.MIN_LAT, Constants.MAX_LNG, Constants.MAX_LAT]);
        }
    }

    async setMap(map: Map) {
        this.map = map;
        this.map.once('load', async () => {
            // Add empty layers to organise layers
            this.addEmptyLayers();
            // load map marker images
            await this.loadImages();
            // add busStop layer
            this.addLayers();
            this._isReady = true;
            this.events.publish('map:ready');
        });
        // this.map.once('render', () => {
        //     this._isReady = true;
        //     this.events.publish('map:ready');
        // });
        this.map.once('styledata', () => this.events.publish('map:styledata'));
        this.map.once('remove', () => this._isReady = false);
    }

    async loadImages() {
        // let prefix = '';
        // if (Capacitor.isNativePlatform() && this.platform.is('android')) {
        //   // absolute paths need to be used on android
        //   // https://github.com/mapbox/mapbox-gl-js/issues/7603
        //   prefix = this.file.applicationDirectory + 'www/';
        // }
        this.markerImages.forEach(name => {
            // this.map.loadImage(`${prefix}assets/markers/${category}@2x.png`, (error, image) => {
            this.map.loadImage(`/assets/markers/${name}@2x.png`, (error, image) => {
                if (error) {
                    console.log(error);
                }
                if (image && this.map) {
                    this.map.addImage(name, image);
                }
            });
        });
        this.map.loadImage('assets/images/direction_indicator.png', (error, image) => {
            if (error) {
                console.log(error);
            }
            if (image && this.map) {
                this.map.addImage('direction_indicator', image);
            }
        });
    }

    /**
     * Layers in maplibre-gl can only be organized vertically by specifying another layer to insert the new layer before.
     * To help arrange layers in a certain manner (e.g. no aerial pictures on top of line features), this function adds
     * empty layers to the map that can be used for the addLayer-function's before argument.
     */
    addEmptyLayers(createSources: boolean = true) {
        const layers = ['baselayersbefore', 'roadnetworksbefore', 'routebelowbefore', 'toursbefore', 'poisbefore', 'nodesbefore'];
        layers.forEach(layer => {
            let layerObj: AddLayerObject;
            if (createSources) {
                layerObj = {
                    id: layer,
                    type: 'symbol',
                    source: {
                        type: 'geojson',
                        data: {
                            type: 'FeatureCollection',
                            features: []
                        }
                    }
                };
            } else {
                layerObj = {
                    id: layer,
                    type: 'symbol',
                    source: layer
                };
            }
            this.map.addLayer(layerObj);
        });
        this.events.publish('map:added-empty-layers');
    }

    rmSimpleTour() {
        if (this.map && this.map.getLayer('simple-tour')) {
            this.removeLayer('simple-tour');
        }
    }

    addSimpleTour(layer) {
        if (this.map) {
            this.map.addLayer(layer, 'toursbefore');
        }
    }

    addLayers() {
        // // add busStops
        // this.busStopProvider.getBusStops().then(busStops => {
        //   this.map.addLayer({
        //     id: 'points',
        //     type: 'symbol',
        //     source: {
        //       type: 'geojson',
        //       data: busStops
        //     },
        //     layout: {
        //       'icon-image': 'busStop',
        //       'icon-size': (1 / 2)
        //     }
        //   });
        // });
    }

    panTo(lnglat: any, offset?: { top: number; right: number; bottom: number; left: number }) {
        if (offset) {
            const offsetX = offset.left - offset.right;
            const offsetY = offset.top - offset.bottom;
            this.map.panTo(lnglat, {offset: [offsetX, offsetY]});
        } else {
            this.map.panTo(lnglat);
        }
    }

    setBaseLayer(layer: any) {
        if (this.activeBaseLayer) {
            this.removeLayer(this.activeBaseLayer.id);
        }
        if (layer) {
            this.addLayer(layer, false, 'baselayersbefore');
        }
        this.activeBaseLayer = layer;
    }

    addLayer(layer: any, fitBounds: boolean = false, before?: string,
             offset?: { top: number; right: number; bottom: number; left: number }) {
        if (this.map) {
            if (this.isReady()) {
                if (this.map.getLayer(layer.id)) {
                    this.removeLayer(layer.id);
                }

                console.log('adding layer in map provider', layer, before);
                this.map.addLayer(layer, before);

                this.events.publish('layer:added-layer');
                this.layers.push({layer, before});

                if (fitBounds) {
                    this.fitLayer(layer, offset);
                }
            } else {
                const subscriptionFirst = this.events.subscribe('map:ready', () => {
                    // TODO test if timeout is still necessary on mobile devices, else remove
                    // setTimeout(() => this.addLayer(layer, fitBounds, before, offset), 500);
                    this.addLayer(layer, fitBounds, before, offset);
                    subscriptionFirst.unsubscribe();
                });
            }
        } else {
            const subscriptionSecond = this.events.subscribe('map:ready', () => {
                this.addLayer(layer, fitBounds, before, offset);
                subscriptionSecond.unsubscribe();
            });
        }
    }

    /**
     * Add a layer after another layer, resulting in the new layer appearing visually above the existing layer.
     */
    addLayerAfter(layer, fitBounds: boolean = false, after: string): void {
        const layers = this.map.getStyle().layers;
        const layerIndex: number = layers.findIndex(l => l.id === after);
        if (layerIndex < layers.length - 1) {
            // Add layer before 'after + 1'
            const nextLayerId: string = layers[layerIndex + 1].id;
            this.addLayer(layer, fitBounds, nextLayerId);
        } else {
            this.addLayer(layer, fitBounds);
        }
    }

    addTargetLocation(point: RoutingPoint, backup?: boolean) {
        if (this.targetMarker) {
            this.targetMarker.remove();
        }
        if (point) {
            this.targetMarker = this.addMarker(point.coordinates, 'custom-marker', {type: 'info'});
        } else if (backup && this.targetMarker) {
            this.targetMarker.addTo(this.map);
        }
    }

    removeTargetLocation() {
        if (this.targetMarker) {
            this.targetMarker.remove();
            // this.targetMarker = null;
        }
    }

    addMarker(lngLat: LngLatLike, icon: string, options: MarkerOptions): Marker {
        // console.log('from addMarker');
        const wrapperEl = document.createElement('div');
        const el: any = document.createElement('div');

        switch (icon) {
            case 'hover':
                wrapperEl.className = 'marker-hover-wrapper';
                el.className = 'marker-hover';
                break;
            case 'invisible':
                wrapperEl.className = 'marker-invisible-wrapper';
                el.className = 'marker-invisible';
                break;
            default:
                wrapperEl.className = 'marker-poi-wrapper';
                el.className = 'marker-poi';
        }

        if (options.featureProperties) {
            el.featureProperties = options.featureProperties; // store poi in element for access in click event
        }
        wrapperEl.appendChild(el);

        const contentEl = document.createElement('div');
        contentEl.className = (icon !== 'hover') ? `marker-poi-content ${icon}` : null;
        el.appendChild(contentEl);

        if (options.clickHandler) {
            let callback = options.clickHandler;
            if (options.thisArg) {
                callback = options.clickHandler.bind(options.thisArg);
            }
            el.addEventListener('click', callback);
        } else {
            el.addEventListener('click', event => {
                event.stopPropagation();
                event.preventDefault();
            });
        }

        if (options.type !== 'hover') {
            // to turn off route's hover effect when the mouse
            // is above a marker
            el.addEventListener('mouseenter', event => {
                event.stopPropagation();
                event.preventDefault();
                this.events.publish('map:marker-hover', true);
            });

            el.addEventListener('mouseleave', event => {
                event.stopPropagation();
                event.preventDefault();
                this.events.publish('map:marker-hover', false);
            });
        }

        const marker = new Marker({element: wrapperEl}).setLngLat(lngLat);

        if (options.draggable) {
            // enable dragging on desktop. Mobile users need to enable dragging by clicking on the marker
            // and choosing 'drag' from the marker menu
            if (!this.platform.is('mobile')) {
                marker.setDraggable(true);
                if (options.type !== 'hover') {
                    marker.on('drag', () => {
                        this.events.publish('map:marker-drag', {event, marker, end: false});
                    });
                    marker.on('dragend', () => {
                        this.events.publish('map:marker-drag', {event, marker, end: true});
                    });
                }
            }
        }

        marker.addTo(this.map);

        el.marker = marker;

        if (options.zoomTo) {
            this.zoomTo(marker.getLngLat(), 15);
        }

        if (options.type === 'info') {
            this.infoMarker = marker;
        } else if (options.type === 'hover') {
            this.hoverMarker = marker;
            // this.events.publish('map:marker-hover-waypoint');
        } else {
            this.markers.push(marker);
        }

        return marker;
    }

    clearMarkers(includeRoutingMarkers: boolean = true) {
        this.markers.forEach((marker: Marker) => {
            marker.remove();
        });
        // Emit event to clear categories.
        this.events.publish('poi:removed-all');
        this.globals.remove('selected-poi-categories');

        if (includeRoutingMarkers) {
            this.routingMarkers.forEach((marker: Marker) => {
                marker.remove();
            });
        }
    }

    fitLayer(layer: any, offset?: { top: number; right: number; bottom: number; left: number }) {
        this.fitGeometry(layer.source.data, offset);
    }

    async fitPoiSelection(pois: Poi[]) {
        const geometry = {
            coordinates: [],
            type: 'MultiPoint'
        };
        pois.forEach(element => {
            geometry.coordinates.push(element.geom.coordinates);
        });
        await this.fitGeometry(geometry);
    }

    async fitToursSelection(tours: Tour[]) {
        console.log(tours);
        const geometry = {
            coordinates: [],
            type: 'MultiPoint'
        };
        tours.forEach(element => {
            geometry.coordinates = geometry.coordinates.concat(element.geom.coordinates);
        });
        await this.fitGeometry(geometry);
    }

    async fitGeometry(geometry: any, offset?: { top: number; right: number; bottom: number; left: number }) {
        console.log('WILL FIT GEOMETRY', geometry);
        let minLng: number;
        let minLat: number;
        let maxLng: number;
        let maxLat: number;
        const setMinMax = (coordinates: number[][]) => {
            coordinates.forEach((coords: number[]) => {
                if (!minLng || minLng > coords[0]) {
                    minLng = coords[0];
                }
                if (!maxLng || maxLng < coords[0]) {
                    maxLng = coords[0];
                }
                if (!minLat || minLat > coords[1]) {
                    minLat = coords[1];
                }
                if (!maxLat || maxLat < coords[1]) {
                    maxLat = coords[1];
                }
            });
        };

        if (geometry.type === 'LineString') {
            [minLng, minLat] = geometry.coordinates[0];
            [maxLng, maxLat] = geometry.coordinates[1];
            setMinMax(geometry.coordinates);
            await this.fitBounds([[minLng, minLat], [maxLng, maxLat]], 50, offset);
        } else if (geometry.type === 'MultiLineString') {
            [minLng, minLat] = geometry.coordinates[0][0];
            [maxLng, maxLat] = geometry.coordinates[0][1];
            geometry.coordinates.forEach(linestringCoords => {
                setMinMax(linestringCoords);
            });
            await this.fitBounds([[minLng, minLat], [maxLng, maxLat]], 50, offset);
        } else if (geometry.type === 'MultiPoint') {
            if (geometry.coordinates.length > 0) {
                [minLng, minLat] = geometry.coordinates[0];
                [maxLng, maxLat] = geometry.coordinates[1];
                setMinMax(geometry.coordinates);
                await this.fitBounds([[minLng, minLat], [maxLng, maxLat]], 50, offset);
            } else {
                await this.fitBounds([geometry.coordinates[0], geometry.coordinates[0]], 50, offset);
            }
        }
    }

    async fitBounds(bounds: LngLatBoundsLike, padding: number = 50, offset?: { top: number; right: number; bottom: number; left: number }) {
        if (this.map) {
            const options: { padding: number | PaddingOptions; linear: boolean } = {padding, linear: true};
            if (offset) {
                options.padding = {
                    top: 50 + offset.top,
                    right: 50 + offset.right,
                    bottom: 50 + offset.bottom,
                    left: 50 + offset.left
                };
            }
            this.map.fitBounds(bounds, options);
        }
    }

    removeLayer(id: any) {
        if (this.map) {
            if (this.map.getLayer(id)) {
                this.map.removeLayer(id);
                UtilService.removeElementFromArray(this.layers, id);
            }
            if (this.map.getSource(id)) {
                this.map.removeSource(id);
            }
        }

    }

    /**
     * Add control to map.
     */
    public addControl(control: IControl) {
        if (this.map) {
            this.map.addControl(control);
        }
    }

    /**
     * Removes control from map.
     */
    public removeControl(control: IControl) {
        try {
            this.map.removeControl(control);
        } catch (e) {
            console.log('error when trying to remove map control');
        }
    }

    // removePois(pois: Poi[]) {
    //     pois.forEach((poi: Poi) => {
    //         let markerId = this.poisMarkersDict[poi.id];
    //         this.markers.removeLayer(markerId);
    //         delete this.poisMarkersDict[poi.id];
    //     });
    //     this.updateMarkers();
    // }

    // private updateMarkers() {
    //     if (this.map.hasLayer(this.markers)) {
    //         this.map.removeLayer(this.markers);
    //     }
    //     this.map.addLayer(this.markers);
    //     this.map.fitBounds(this.markers.getBounds());
    // }

    off(type: string[], listener: any, layer?) {
        type.forEach(event => {
            if (this.map) {
                this.map.off(event, listener);
            }
        });
    }

    on(type: string[], listener: any, thisArg?: any, once: boolean = false) {
        thisArg = thisArg || this;
        type.forEach(event => {
            if (once) {
                const fn = (e: MapMouseEvent) => {
                    listener.call(thisArg, e);
                    this.off([event], fn);
                };
                this.map.on(event, fn);
            } else {
                this.map.on(event, listener.bind(thisArg));
            }
        });
    }

    onLongTouch(listener: any, offListener?: any, thisArg?: any) {
        thisArg = thisArg || this;
        let centerStart: LngLat;
        let centerStop: LngLat;
        let id;

        this.map.on('touchstart', (event) => {
            centerStart = this.map.getCenter();
            id = setTimeout(() => {
                centerStop = this.map.getCenter();
                if (offListener && centerStart.lng === centerStop.lng && centerStart.lat === centerStop.lat) {
                    listener.call(thisArg, event);
                }
                id = null;
            }, 500);
        });

        this.map.on('touchend', (event) => {
            if (id) {
                clearTimeout(id);
                centerStop = this.map.getCenter();
                if (offListener && centerStart.lng === centerStop.lng && centerStart.lat === centerStop.lat) {
                    offListener.call(thisArg, event);
                }
            }
        });
    }

    zoomTo(lngLat: LngLatLike, zoom: number) {
        this.map.setZoom(zoom);
        this.map.setCenter(lngLat);

    }

    getCenter(): LngLatLike {
        return this.map.getCenter();
    }

    getLayer(id: string) {
        return this.map.getLayer(id);
    }

    getLayers(): any[] {
        return this.layers;
    }

    getMarkers(): Marker[] {
        return this.markers;
    }

    getZoom(): number {
        if (this.map) {
            return this.map.getZoom();
        } else {
            return Constants.ZOOM;
        }
    }

    restore(mapConfig: { center: LngLatLike; zoom: number; layers: any[]; markers: Marker[]; routingMarkers?: Marker[] }) {
        mapConfig.layers.forEach(layer => {
            this.map.addLayer(layer);
        });
        mapConfig.markers.forEach(marker => {
            marker.addTo(this.map);
        });
        if (mapConfig.routingMarkers) {
            mapConfig.routingMarkers.forEach(marker => {
                marker.addTo(this.map);
            });
        }
        this.map.setCenter(mapConfig.center);
        this.map.setZoom(mapConfig.zoom);
    }

    addBusStopLayer(busStops: BusStop[], clickCallback: any, zoomTo: boolean = false) {
        this.rmBusStopLayer();

        // create feature array
        const features = new Array<Feature<Point, GeoJsonProperties>>();
        busStops.forEach(busStop => {
            features.push({
                type: 'Feature',
                geometry: busStop.geom,
                properties: {
                    uuid: busStop.uuid,
                    id: busStop.id,
                    type: busStop.stoptype
                }
            });
        });


        this.busStopLayer = {
            id: 'busStop-layer',
            type: 'symbol',
            minzoom: 14,
            source: {
                type: 'geojson',
                data: {
                    type: 'FeatureCollection',
                    features
                }
            },
            layout: {
                'icon-allow-overlap': true,
                'icon-ignore-placement': true,
                'icon-image': '98_{type}',
                'icon-size': (1 / 2)
            }
        };
        this.addLayer(this.busStopLayer, zoomTo, 'poisbefore');

        // save listener in clickListeners object for later removal in rmPoiClusterLayers
        this.clickListeners[this.busStopLayer.id] = event => {
            event.originalEvent.stopPropagation();
            // this.events.publish('map:marker-click', [event]);
            clickCallback.call(null, event);
        };
        // when clicked on marker execute callback
        this.map.on('click', this.busStopLayer.id, this.clickListeners[this.busStopLayer.id]);

        // Change the cursor to a pointer when the mouse is over the poi layer.
        this.map.on('mouseenter', this.busStopLayer.id, this.onMouseEnter.bind(this));
        // Change it back to a pointer when it leaves.
        this.map.on('mouseleave', this.busStopLayer.id, this.onMouseLeave.bind(this));
    }

    rmBusStopLayer() {
        if (this.busStopLayer) {
            this.removeLayer(this.busStopLayer.id);

            // remove click listeners
            this.off(['click'], this.clickListeners[this.busStopLayer.id]);
            if (this.map) {
                this.map.off('click', this.busStopLayer.id, this.clickListeners[this.busStopLayer.id]);
                // remove mouseenter/mouseleave listeners
                this.map.off('mouseenter', this.busStopLayer.id, this.onMouseEnter.bind(this));
                this.map.off('mouseleave', this.busStopLayer.id, this.onMouseLeave.bind(this));
            }

            delete this.clickListeners[this.busStopLayer.id];

            this.busStopLayer = null;
        }
    }

    addPoiClusterByCategory(
        codes: number[],
        pois: Poi[],
        name: 'pois' | 'nodes' | 'busStops' | 'selected-poi',
        clustered: boolean,
        clusterRadius: number = 50,
        clickCallback?: any,
        fitBounds: boolean = false
    ) {


        const features = new Array<Feature<Point>>();
        const bounds = new LngLatBounds();

        const addMapElements = () => {
            // clear map in case layer already exists
            this.rmPoiClusterLayers([name]);
            this.map.addSource(`${name}-src`, {
                type: 'geojson',
                data: {
                    type: 'FeatureCollection',
                    features
                },
                cluster: clustered,
                clusterMaxZoom: 15,
                clusterRadius
            });

            // to prevent poi layers from lying on top of the node layer, they are inserted before the other layers
            let before = name === 'nodes' ? 'nodesbefore' : 'poisbefore';

            if (clustered) {
                // cluster symbol layer (cluster count)
                this.addLayer({
                    id: `${name}-clusters`,
                    type: 'symbol',
                    source: `${name}-src`,
                    filter: ['has', 'point_count'],
                    layout: {
                        'icon-allow-overlap': true,
                        'icon-ignore-placement': true,
                        'icon-image': `cluster-${name}`,
                        'icon-size': (1 / 2)
                    }
                }, false, before);
                before = `${name}-clusters`;
            }

            // marker symbol layer
            codes.forEach(categorycode => {
                    const layout: any = {
                        'icon-allow-overlap': true,
                        'icon-ignore-placement': true,
                        'icon-image': categorycode.toString(),
                        'icon-size': (1 / 2)
                    };
                    if (categorycode === 99) {
                        layout['text-field'] = '{nodeNumber}';
                        layout['text-font'] = ['KlokanTech Noto Sans Bold'];
                        layout['text-allow-overlap'] = true;
                    }
                    const id = `${name}-markers-${categorycode}`;
                    this.addLayer({
                        id,
                        type: 'symbol',
                        source: `${name}-src`,
                        filter: ['all', ['!', ['has', 'point_count']], ['==', ['get', 'category'], categorycode]],
                        layout,
                        paint: {
                            'text-color': '#ffffff'
                        }
                    }, false, before);

                    const header = this.globals.get('headerVisible') !== false; // true by default
                    if (clickCallback && header) {
                        // save listener in clickListeners object for later removal in rmPoiClusterLayers
                        this.clickListeners[id] = event => {
                            event.originalEvent.stopPropagation();
                            // this.events.publish('map:marker-click', [event]);
                            clickCallback.call(null, event);
                        };
                        // when clicked on marker execute callback
                        this.map.on('click', id, this.clickListeners[id]);

                        // Change the cursor to a pointer when the mouse is over the poi layer.
                        this.map.on('mouseenter', id, this.onMouseEnter.bind(this));
                        // Change it back to a pointer when it leaves.
                        this.map.on('mouseleave', id, this.onMouseLeave.bind(this));
                    }
                }
            );

            before = `${name}-markers-${codes[0]}`;

            // add click handlers
            if (clustered) {
                // save listener in clickListeners object for later removal in rmPoiClusterLayers
                this.clickListeners[`${name}-clusters`] = event => {
                    const clusterFeatures = this.map.queryRenderedFeatures(event.point, {layers: [`${name}-clusters`]});
                    const clusterId = clusterFeatures[0].properties.cluster_id;
                    (this.map.getSource(`${name}-src`) as GeoJSONSource).getClusterExpansionZoom(clusterId, (err, zoom) => {
                        if (err) {
                            return;
                        }

                        this.map.easeTo({
                            center: ((clusterFeatures[0].geometry as Point).coordinates as [number, number]),
                            zoom
                        });
                    });
                };
                // when clicked on a cluster, zoom to clustered features
                this.map.on('click', `${name}-clusters`, this.clickListeners[`${name}-clusters`]);
                // Change the cursor to a pointer when the mouse is over a cluster.
                this.map.on('mouseenter', `${name}-clusters`, this.onMouseEnter.bind(this));
                // Change it back to a pointer when it leaves.
                this.map.on('mouseleave', `${name}-clusters`, this.onMouseLeave.bind(this));
            }
        };

        // convert pois to features
        pois.forEach(poi => {
            const poiCategory = poi.categorycodes.find(code => codes.indexOf(code) !== -1);
            if (poiCategory) {

                // extract number from description for nodes
                const node = poi.categorycodes.indexOf(99) !== -1;
                let nodeNumber = null;
                if (node) {
                    if (this.util.getTranslation(poi).description) {
                        const nodeItems = this.util.getTranslation(poi).description.split('-');
                        nodeNumber = nodeItems[nodeItems.length - 1];
                    }
                }

                // add new feature to array
                features.push({
                    type: 'Feature',
                    geometry: poi.geom,
                    properties: {
                        id: poi.id || poi.uuid,
                        category: poiCategory,
                        node,
                        nodeNumber
                    }
                });

                // extend bounds by newly created feature
                bounds.extend(new LngLat(poi.geom.coordinates[0], poi.geom.coordinates[1]));
            }
        });

        const onReady = () => {
            try {
                addMapElements();
            } catch (e) {
                console.log(e);
            }

            if (fitBounds) {
                if (pois.length > 1) {
                    this.map.fitBounds(bounds);
                } else {
                    this.zoomTo(pois[0].geom.coordinates, 15);
                }
            }
            if (this.subscriptions['map:ready']) {
                (this.subscriptions['map:ready'] as Subscription).unsubscribe();
            }
        };

        // finally add features to map either directly if map object is availabe or after the map:styledata event has been fired
        if (this.map && this.isReady()) {
            onReady();
        } else {
            const subscriptionReady = this.events.subscribe('map:ready', () => {
                onReady();
                subscriptionReady.unsubscribe();
            });
        }
    }

    onMouseEnter() {
        this.map.getCanvas().style.cursor = 'pointer';
    }

    onMouseLeave() {
        this.map.getCanvas().style.cursor = '';
    }

    rmPoiClusterLayers(names: ('pois' | 'nodes' | 'busStops' | 'selected-poi')[]) {
        const layers = [];
        const sources = [];

        names.forEach(name => {
            layers.push(`${name}-clusters`);
            sources.push(`${name}-src`);
        });

        if (names.indexOf('pois') !== -1) {
            this.poiCodes.forEach(code => layers.push(`pois-markers-${code}`));
        }
        if (names.indexOf('selected-poi') !== -1) {
            this.poiCodes.forEach(code => layers.push(`selected-poi-markers-${code}`));
        }
        if (names.indexOf('nodes') !== -1) {
            layers.push('nodes-markers-99');
        }
        if (names.indexOf('busStops') !== -1) {
            layers.push('busStops-markers-98');
        }

        // pois-marker-symbol-381262
        if (this.map) {
            layers.forEach(layerId => {
                if (this.map.getLayer(layerId)) {
                    this.map.removeLayer(layerId);
                }
            });
            names.forEach(name => {
                if (this.map.getSource(`${name}-src`)) {
                    this.map.removeSource(`${name}-src`);
                }
            });
            names.forEach(name => {
                const keys = Object.keys(this.clickListeners).filter(key => key.match(new RegExp('^' + name + '.*')));
                keys.forEach(key => {
                    if (this.map) {
                        // remove click listeners
                        this.map.off('click', key, this.clickListeners[key]);
                        // remove mouseenter/mouseleave listeners
                        this.map.off('mouseenter', key, this.onMouseEnter.bind(this));
                        this.map.off('mouseleave', key, this.onMouseLeave.bind(this));
                    }
                    delete this.clickListeners[key];
                });
            });
        }
    }

// /**
//  * Uses the user location to set a marker
//  * @param point start/end
//  * @param coord coordinates of the user
//  */
// useLocation(point, coord) {
//     let coordinates;
//     let waypoints = [];
//     let lat = coord.latlng.lat;
//     let lng = coord.latlng.lng;
//     coordinates = [lat, lng];
//     if (point === 'start') {
//         //to emit the coordinates for the routing
//         this.changeStartCoordinates(coordinates);
//         this.updateRouteMarker('start', coordinates, waypoints);
//     } else if (point === 'end') {
//         this.changeEndCoordinates(coordinates);
//         this.updateRouteMarker('end', coordinates, waypoints);
//     }
// }

    addSource(id: string, source) {
        this.map.addSource(id, source);
    }

    removeSource(id: string) {
        this.map.removeSource(id);
    }

    refresh() {
        this.map.resize();
    }

    setStyle(styleUrl: string) {
        const oldStyle: StyleSpecification = this.map.getStyle();
        this.http.get(styleUrl).subscribe((newStyle: StyleSpecification) => {
            this.map.once('styledata', async () => {
                // add empty layers without sources (already added)
                this.addEmptyLayers(false);
                await this.loadImages();
                // add all layers that were present on the old map style
                this.layers.forEach(entry => {
                    // sources contained within layers need to be removed since they have already been added
                    if (typeof entry.layer.source !== "string") {
                        entry.layer.source = entry.layer.id;
                    }
                    try {
                        this.map.addLayer(entry.layer, entry.before);
                    } catch (e) {
                        // TODO some layer are added twice, leading to errors
                    }
                });
            });

            // copy all sources from old map style
            newStyle.sources = Object.assign({}, oldStyle.sources, newStyle.sources); // ensure any sources from the current style are copied across to the new style
            // set new style, triggering styledata event handled above
            this.map.setStyle(newStyle, {diff: false});
        });
    }
}
