import {ControlPosition, IControl, Map} from 'maplibre-gl';
import {DOM} from './dom';

export class TurnByTurnNavigationControl implements IControl {
  public container: HTMLElement;
  private map: Map;
  private className = 'maplibregl-ctrl';
  private button: HTMLButtonElement;

  constructor() {
  }

  /**
   * @implements {IControl.onAdd}
   * @param map
   */
  onAdd(map: Map): HTMLElement {
    this.map = map;
    this.container = DOM.create('div', `${this.className} ${this.className}-group`);
    this.button = (<HTMLButtonElement>DOM.create('button', `${this.className}-icon ${this.className}-geolocate`, this.container));
    this.button.type = 'button';
    this.button.setAttribute('aria-label', 'Layer menu');
    this.button.addEventListener('click', event => {
      event.stopPropagation();
      this.trigger();
    });
    return this.container;
  }

  /**
   * @implements {IControl.onRemove}
   */
  onRemove() {
    DOM.remove(this.container);
  }

  /**
   * @implements {IControl.getDefaultPosition}
   */
  getDefaultPosition(): ControlPosition {
    return 'top-right';
  }


  trigger() {
    console.log('Compass button clicked.');
    // Trigger geolocation
  }
}
