// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.lang-switcher button {
  margin: 0 1.5px;
}

ion-menu ion-item-group > ion-item > ion-icon {
  color: #585858;
}
ion-menu .app-logo img {
  height: 125px;
  width: 125px;
}

.menu-items {
  align-items: stretch;
  height: 100%;
  width: 100%;
}

.landscape-header .landscape-header-toolbar .button-toolbar {
  --color: var(--ion-color-primary-contrast) ;
}

.menu-side-start > ion-header {
  position: relative;
}
.menu-side-start > ion-header .user-header {
  height: 25vh;
}
.menu-side-start > ion-header .user-header .button {
  bottom: 0;
  position: absolute;
  text-transform: none;
  width: 100%;
}
.menu-side-start > ion-header .user-header .button ion-icon {
  margin-left: auto;
}
.menu-side-start > ion-header .user-header .user-avatar {
  position: absolute;
  left: 2rem;
  top: 2rem;
  height: 8rem;
  width: 8rem;
  border-radius: 8rem;
  background: white;
}
.menu-side-start > ion-header.header-ios {
  border-bottom: 0.55px solid rgba(0, 0, 0, 0.3);
}
.menu-side-start > ion-header.header-ios .user-avatar {
  border: 0.55px solid rgba(0, 0, 0, 0.3);
}
.menu-side-start > ion-header.header-md {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
}
.menu-side-start > ion-header.header-md .button {
  color: white;
}
.menu-side-start > ion-header.header-md .user-header {
  background: var(--ion-color-primary);
}`, "",{"version":3,"sources":["webpack://./src/app/app.component.scss"],"names":[],"mappings":"AACE;EACE,eAAA;AAAJ;;AAKE;EACE,cAAA;AAFJ;AAMI;EACE,aAAA;EACA,YAAA;AAJN;;AASA;EACE,oBAAA;EACA,YAAA;EACA,WAAA;AANF;;AAUE;EACE,2CAAA;AAPJ;;AAYE;EACE,kBAAA;AATJ;AAWI;EACE,YAAA;AATN;AAWM;EACE,SAAA;EACA,kBAAA;EACA,oBAAA;EACA,WAAA;AATR;AAWQ;EACE,iBAAA;AATV;AAaM;EACE,kBAAA;EACA,UAAA;EACA,SAAA;EACA,YAAA;EACA,WAAA;EACA,mBAAA;EACA,iBAAA;AAXR;AAeI;EACE,8CAAA;AAbN;AAeM;EACE,uCAAA;AAbR;AAiBI;EACE,wCAAA;AAfN;AAiBM;EACE,YAAA;AAfR;AAkBM;EACE,oCAAA;AAhBR","sourcesContent":[".lang-switcher {\n  button {\n    margin: 0 1.5px;\n  }\n}\n\nion-menu {\n  ion-item-group > ion-item > ion-icon {\n    color: #585858;\n  }\n\n  .app-logo {\n    img {\n      height: 125px;\n      width: 125px;\n    }\n  }\n}\n\n.menu-items {\n  align-items: stretch;\n  height: 100%;\n  width: 100%;\n}\n\n.landscape-header .landscape-header-toolbar {\n  .button-toolbar {\n    --color: var(--ion-color-primary-contrast)\n  }\n}\n\n.menu-side-start {\n  > ion-header {\n    position: relative;\n\n    .user-header {\n      height: 25vh;\n\n      .button {\n        bottom: 0;\n        position: absolute;\n        text-transform: none;\n        width: 100%;\n\n        ion-icon {\n          margin-left: auto;\n        }\n      }\n\n      .user-avatar {\n        position: absolute;\n        left: 2rem;\n        top: 2rem;\n        height: 8rem;\n        width: 8rem;\n        border-radius: 8rem;\n        background: white;\n      }\n    }\n\n    &.header-ios {\n      border-bottom: 0.55px solid rgba(0, 0, 0, 0.3);\n\n      .user-avatar {\n        border: 0.55px solid rgba(0, 0, 0, 0.3);\n      }\n    }\n\n    &.header-md {\n      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);\n\n      .button {\n        color: white;\n      }\n\n      .user-header {\n        background: var(--ion-color-primary);\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
