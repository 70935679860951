import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';

import {IonicModule} from '@ionic/angular';

import {TranslateModule} from '@ngx-translate/core';
import {DistancePipeModule} from '../../pipes/distance.module';
import {ToolbarSearchbarComponent} from './toolbar-searchbar.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    TranslateModule,
    DistancePipeModule
  ],
  exports: [
    ToolbarSearchbarComponent
  ],
  declarations: [ToolbarSearchbarComponent]
})
export class ToolbarSearchbarComponentModule {
}
