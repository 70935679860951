import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';

import {IonicModule} from '@ionic/angular';

import {TranslateModule} from '@ngx-translate/core';
import {DarkModeSwitchComponent} from './dark-mode-switch.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    TranslateModule
  ],
  exports: [
    DarkModeSwitchComponent
  ],
  declarations: [DarkModeSwitchComponent]
})
export class DarkModeSwitchComponentModule {
}
