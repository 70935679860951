import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';

import {IonicModule} from '@ionic/angular';
import {DistancePipe} from './distance.pipe';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule
  ],
  exports: [
    DistancePipe
  ],
  declarations: [DistancePipe]
})
export class DistancePipeModule {
}
