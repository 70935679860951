import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GlobalsService {

  private _data = {};

  public get(key: string, defaultValue?: any): any {
    if (this._data.hasOwnProperty(key)) {
      return this._data[key];
    } else if (defaultValue) {
      return defaultValue;
    } else {
      return undefined;
    }
  }

  public hasKey(key: string): boolean {
    return this._data.hasOwnProperty(key);
  }

  public remove(key: string) {
    if (this.hasKey(key)) {
      delete this._data[key];
    }
  }

  public set(key: string, value: any, overwrite: boolean = true) {
    if (!this.hasKey(key) || overwrite) {
      this._data[key] = value;
    }
  }
}
