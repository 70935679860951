import {Component, Input} from '@angular/core';
import {ModalController} from '@ionic/angular';

@Component({
  selector: 'app-about-usage',
  templateUrl: './about-usage.page.html',
  styleUrls: ['./about-usage.page.scss'],
})
export class AboutUsagePage {

  @Input() isModal = false;

  constructor(
    private modalCtrl: ModalController
  ) {
  }

  async dismissModal() {
    await this.modalCtrl.dismiss();
  }

}
