import {DOM} from './dom';

import {IControl, Map} from 'maplibre-gl';
import {bindAll, warnOnce} from '../util/util';
import {TranslateService} from '@ngx-translate/core';

type Options = {
  container?: HTMLElement;
};

/**
 * A `FullscreenControl` control contains a button for toggling the map in and out of fullscreen mode.
 *
 * @implements {IControl}
 * @param {Object} [options]
 * @param {HTMLElement} [options.container] `container` is the [compatible DOM element]
 * (https://developer.mozilla.org/en-US/docs/Web/API/Element/requestFullScreen#Compatible_elements)
 * which should be made full screen. By default, the map container element will be made full screen.
 *
 * @example
 * map.addControl(new maplibregl.FullscreenControl({container: document.querySelector('body')}));
 * @see [View a fullscreen map](https://maplibre.org/maplibre-gl-js-docs/example/fullscreen/)
 */

class FullscreenControl implements IControl {
  _map: Map;
  _controlContainer: HTMLElement;
  _fullscreen: boolean;
  _fullscreenButton: HTMLButtonElement;
  _container: HTMLElement;

  constructor(
    private translate: TranslateService,
    options?: Options
  ) {
    this._fullscreen = false;
    if (options && options.container) {
      if (options.container instanceof HTMLElement) {
        this._container = options.container;
      } else {
        warnOnce('Full screen control \'container\' must be a DOM element.');
      }
    }
    bindAll([
      '_onClickFullscreen'
    ], this);
  }

  onAdd(map: Map) {
    this._map = map;
    if (!this._container) {
      this._container = this._map.getContainer();
    }
    this._controlContainer =
      DOM.create('div', 'maplibregl-ctrl maplibregl-ctrl-group maplibregl-ctrl maplibregl-ctrl-group');
    this._setupUI();
    return this._controlContainer;
  }

  onRemove() {
    DOM.remove(this._controlContainer);
    this._map = null;
  }

  _setupUI() {
    const button = this._fullscreenButton =
      DOM.create('button', (('maplibregl-ctrl-fullscreen maplibregl-ctrl-fullscreen')),
        this._controlContainer) as HTMLButtonElement;
    DOM.create('span', 'maplibregl-ctrl-icon maplibregl-ctrl-icon', button)
      .setAttribute('aria-hidden', 'true');
    button.type = 'button';
    this._fullscreenButton.addEventListener('click', this._onClickFullscreen);

    this.translate.get('map-controls.maximize-map')
      .subscribe(translation => this._fullscreenButton.title = translation);
  }

  _isFullscreen() {
    return this._fullscreen;
  }

  _onClickFullscreen() {
    console.log(this._isFullscreen());

    let collection = document.getElementsByClassName('menu-content');
    const app: HTMLElement = collection.length > 0 ? collection[0] as HTMLElement : null;

    collection = document.getElementsByClassName('landscape-header');
    const header: HTMLElement = collection.length > 0 ? collection[0] as HTMLElement : null;

    if (this._isFullscreen()) {
      if (header) {
        app.style.display = 'block';
        header.style.display = 'block';
        this._container.classList.remove('map-full-screen');
        this.translate.get('map-controls.maximize-map')
          .subscribe(translation => this._fullscreenButton.title = translation);
      }
    } else {
      if (header) {
        app.style.display = 'none';
        header.style.display = 'none';
        this._container.classList.add('map-full-screen');
        this.translate.get('map-controls.minimize-map')
          .subscribe(translation => this._fullscreenButton.title = translation);
      }
    }

    this._fullscreen = !this._fullscreen;
    this._fullscreenButton.classList.toggle('maplibregl-ctrl-shrink');
    this._fullscreenButton.classList.toggle('maplibregl-ctrl-shrink');
    this._fullscreenButton.classList.toggle('maplibregl-ctrl-fullscreen');
    this._fullscreenButton.classList.toggle('maplibregl-ctrl-fullscreen');
  }
}

export default FullscreenControl;
