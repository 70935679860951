import {Injectable} from '@angular/core';
import {AlertController, Platform} from '@ionic/angular';
import {EventsService} from './events.service';
import {TranslateService} from '@ngx-translate/core';
import {AndroidSettings, IOSSettings, NativeSettings} from 'capacitor-native-settings';
import {Capacitor} from '@capacitor/core';
import {Network} from '@capacitor/network';
import {firstValueFrom} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class ConnectionService {

  alertPresent = false;
  notifyNetworkChange = false;

  constructor(
    private alertCtrl: AlertController,
    private events: EventsService,
    private platform: Platform,
    private translate: TranslateService
  ) {
  }


  setSubscriptions() {
    // subscribe to the change of getting connected to the internet
    Network.addListener('networkStatusChange', connected => {
      if (connected) {
        this.events.publish('internet-connections:connected');
      } else {
        this.events.publish('internet-connections:disconnected');
      }
    });
  }

  async getUserInternetConnection() {
    return new Promise(async (resolve, reject) => {
      // resolve if there's internet connection
      if (await this.checkConnection()) {
        resolve(null);
      } else {
        // prevent showing a hundred alerts
        if (!this.alertPresent) {
          const header = await firstValueFrom(this.translate.get('services.util.no-connection-title'));
          const subHeader = await firstValueFrom(this.translate.get('services.util.enable-connection-message'));
          const btnClose = await firstValueFrom(this.translate.get('shared.close'));
          const btnSettings = await firstValueFrom(this.translate.get('services.util.enable-connection-btn-settings'));
          this.alertPresent = true;

          let alertButtons = [{
            text: btnClose,
            role: 'cancel',
            handler: () => {
              // the user ignored being notified that no internet connection is there
              this.alertPresent = false;
              reject();
            }
          }];

          if (Capacitor.isNativePlatform()) {
            alertButtons.push({
              text: btnSettings,
              role: 'some-role',
              handler: () => {
                // the user went to change settings and should be notified when connection appears
                this.notifyNetworkChange = true;
                if (this.platform.is('ios')) {
                  // opening general settings on ios because using other options might break in future iOS versions
                  // or have your app rejected from the App Store.
                  NativeSettings.openIOS({
                    option: IOSSettings.App
                  });
                } else {
                  // opening the settings page otherwise
                  NativeSettings.openAndroid({
                    option: AndroidSettings.Settings
                  });
                }
                this.alertPresent = false;
                reject();
              }
            })
          }

          const prompt = await this.alertCtrl.create({
            header,
            subHeader,
            buttons: alertButtons
          });
          await prompt.present();
        }
      }
    });
  }

  async checkConnection() {
    // resolve if there's internet connection
    const status = await Network.getStatus();
    if (status) {
      // on firefox is 'unknown' somehow
      return (status.connectionType !== 'none');
    } else {
      return true; // wired connection (?)
    }
  }

}
