import {Injectable} from '@angular/core';
import {AlertController, LoadingController, Platform, ToastController} from '@ionic/angular';
import {TranslateService} from '@ngx-translate/core';
import {EventsService} from './events.service';
import {ChartConfiguration} from 'chart.js';
import {LineString, Point} from 'geojson';
import {LngLat, LngLatBounds, LngLatBoundsLike, Marker} from 'maplibre-gl';
import * as turf from '@turf/turf';
import {Constants} from '../var/constants';
import {ErrorResponse} from './user.service';
import {Media, Tour, Translatable, Translation} from '../lib/types/radrevier-ruhr';
import {Position} from '@capacitor/geolocation';
import {firstValueFrom} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class UtilService {

  pageSegments = {
    anmelden: 'LoginPage',
    benutzerprofil: 'UserProfile',
    'eigene-touren': 'UserTours',
    einstellungen: 'SettingsPage',
    entdecken: 'HighlightsPage',
    favoriten: 'UserFavorites',
    karte: 'MapPage',
    'ort-details': 'PoiDetails',
    orte: 'PoisPage',
    start: 'HomePage',
    suche: 'SearchPage',
    'tour-details': 'TourDetails',
    touren: 'ToursPage',
    tourenplaner: 'RoutingPage',
    aktivieren: 'AccountActivatePage',
    wiederherstellen: 'AccountRecoveryPage'
  };

  private alert: HTMLIonAlertElement;
  private loadingSpinner: HTMLIonLoadingElement;

  constructor(
    private alertCtrl: AlertController,
    private events: EventsService,
    private loadingCtrl: LoadingController,
    private platform: Platform,
    private toastCtrl: ToastController,
    private translate: TranslateService
  ) {
  }


  static stringToClassName(name: string) {
    // convert to lower case
    name = name.toLowerCase();
    // replace spaces
    name = name.replace(/\s/g, '_');
    // replace special characters
    name = name.replace(/ä/g, 'ae');
    name = name.replace(/ö/g, 'oe');
    name = name.replace(/ü/g, 'ue');
    name = name.replace(/ß/g, 'ss');
    return name;
  }

  static removeUmlaute(text: string) {
    text = text.replace(/Ä/g, 'Ae');
    text = text.replace(/ä/g, 'ae');
    text = text.replace(/Ö/g, 'Oe');
    text = text.replace(/ö/g, 'oe');
    text = text.replace(/Ü/g, 'Ue');
    text = text.replace(/ü/g, 'ue');
    text = text.replace(/ß/g, 'ss');
    return text;
  }

  static base64toBlob(base64Data, contentType = '', sliceSize = 512) {
    if (base64Data.match(/;base64,/)) {
      base64Data = base64Data.split(';base64,')[1];
    }
    const byteCharacters = atob(base64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, { type: contentType });
  }

  static arrify(obj: any): any[] {
    const result = [];
    for (const key of obj) {
      result.push({ key, value: obj[key] });
    }
    return result;
  }

  static checkWebpSupport() {
    const img = new Image();
    img.onload = () => {
      const result = (img.width > 0) && (img.height > 0);
      if (result) {
        document.getElementsByTagName('ion-app')[0].classList.add('webp');
      } else {
        document.getElementsByTagName('ion-app')[0].classList.add('no-webp');
      }
    };
    img.onerror = () => {
      document.getElementsByTagName('ion-app')[0].classList.add('no-webp');
    };
    img.src = `data:image/webp;base64,UklGRkoAAABXRUJQVlA4WAoAAAAQAAAAAAAAAAAAQUxQSAwAAAARBxAR/
    Q9ERP8DAABWUDggGAAAABQBAJ0BKgEAAQAAAP4AAA3AAP7mtQAAAA==`;
  }

  static reverseCoordinates(coords: any[]): any[] {
    if (coords.length > 3 || typeof (coords[0]) === 'object') {
      coords.forEach((coordinate, index) => coords[index] = UtilService.reverseCoordinates(coordinate));
      return coords;
    } else {
      if (coords.length === 2) {
        return [coords[1], coords[0]];
      } else {
        return [coords[1], coords[0], coords[2]];
      }
    }
  }

  static shortenUrl(url: string, radically: boolean = false, maxLength?: number) {
    let shortened: string;
    let regex = /^(?:https?:\/\/)?(?:w{3}\.)?([\w.-]+(?:\/[\w-]+?\/)?.*?)(?:\.html?)?$/;
    if (maxLength || !radically) {
      shortened = url.replace(regex, '$1');
    }
    if (radically || (maxLength && shortened.length > maxLength)) {
      regex = /^(?:https?:\/\/)?(?:w{3}\.)?([\w.-]+)(?:\/[\w-]+?\/)?.*?$/;
      shortened = url.replace(regex, '$1');
    }
    return shortened;
  }

  static capitalize(s) {
    if (typeof s !== 'string') {
      return '';
    }
    return s.charAt(0).toUpperCase() + s.slice(1);
  }

  static createChartConfig(tour: Tour): ChartConfiguration {
    if (tour.heightchart) {
      const zMin = Math.floor(tour.altitudemin / 50) * 50;
      let zMax = Math.ceil(tour.altitudemax / 100) * 100;
      zMax = zMax < 200 ? 200 : zMax;
      const data = [];
      let labels = [];
      let step = 1;
      if (tour.heightchart.values.length > 100) {
        step = Math.floor(tour.heightchart.values.length / 100);
      }
      tour.heightchart.values.forEach((entry, index) => {
        if (index % step === 0) {
          data.push(entry);
          const distance = index * tour.heightchart.step;
          if (index === 0) {
            labels.push(distance);
          } else {
            labels.push(null);
          }
        }
      });
      data.push(tour.heightchart.values[tour.heightchart.values.length - 1]);
      labels.push(tour.heightchart.last);
      labels = labels.map(value => {
        if (typeof (value) === 'number') {
          return (Math.round(value / 1000)) + 'km';
        } else {
          return '';
        }
      });
      return {
        type: 'line',
        data: {
          labels,
          datasets: [{
            borderColor: 'rgb(193, 87, 62)',
            data,
            fill: {
              target: 'origin'
            }
          }]
        },
        // Configuration options go here
        // The legend is hiddenn. The gridlines are hidden.
        options: {
          plugins: {
            legend: {
              display: false
            },
            tooltip: {
              enabled: false
            }
          },
          elements: {
            point: {
              radius: 0
            }
          },
          scales: {
            y: {
              grid: {
                display: false
              },
              min: zMin,
              max: zMax
            },
            x: {
              grid: {
                display: false
              },
              ticks: {
                // maxTicksLimit: 2,
                autoSkip: false,
                maxRotation: 0,
                minRotation: 0
              }
            }
          }
        }
      };
    } else {
      return null;
    }
  }

  // New Chart.js version that misses the last x-axis label
  // static createChartConfig(tour: Tour): ChartConfiguration {
  //   if (tour.heightchart) {
  //     const yMin = Math.floor(tour.altitudemin / 50) * 50;
  //     let yMax = Math.ceil(tour.altitudemax / 100) * 100;
  //     yMax = yMax < 200 ? 200 : yMax;
  //     const data = [];
  //     let labels = [];
  //     let step = 1;
  //     if (tour.heightchart.values.length > 100) {
  //       step = Math.floor(tour.heightchart.values.length / 100);
  //     }
  //     tour.heightchart.values.forEach((entry, index) => {
  //       if (index % step === 0) {
  //         data.push(entry);
  //         const distance = index * tour.heightchart.step;
  //         if (index === 0) {
  //           labels.push(distance);
  //         } else {
  //           labels.push(null);
  //         }
  //       }
  //     });
  //     data.push(tour.heightchart.values[tour.heightchart.values.length - 1]);
  //     labels.push(tour.heightchart.last);
  //     labels = labels.map(value => {
  //       if (typeof (value) === 'number') {
  //         return (Math.round(value / 1000)) + 'km';
  //       } else {
  //         return '';
  //       }
  //     });
  //     return {
  //       type: 'line',
  //       data: {
  //         labels,
  //         datasets: [{
  //           borderColor: 'rgb(193, 87, 62)',
  //           data
  //         }]
  //       },
  //       // Configuration options go here
  //       // The legend is hidden. The gridlines are hidden.
  //       options: {
  //         elements: {
  //           point: {
  //             radius: 0
  //           }
  //         },
  //         scales: {
  //           x: {
  //             grid: {
  //               display: false
  //             },
  //             min: 0,
  //             max: tour.heightchart.last || tour.length,
  //             ticks: {count: 2}
  //           },
  //           y: {
  //             grid: {
  //               display: false
  //             },
  //             min: yMin,
  //             max: yMax,
  //             ticks: {count: 3}
  //           }
  //         },
  //         plugins: {
  //           legend: {
  //             display: false
  //           },
  //           tooltip: {
  //             enabled: false
  //           }
  //         }
  //       }
  //     };
  //   } else {
  //     return null;
  //   }
  // }

  static formatDistanceString(value: number): string {
    value = Math.round(value / 10) * 10; // round by 10
    if (value < 1000) {
      return value + 'm';
    } else {
      if (value <= 5000) {
        value = Math.round(value / 100) / 10; // round by 100, convert to km
      } else {
        value = Math.round(value / 1000); // round by 1000, convert to km
      }
      let strValue: string = value.toString();
      if (value % 1 !== 0) {
        strValue = strValue.replace(/\./, ',');
      }
      return strValue + 'km';
    }
  }

  static getDevicePixelRatio() {
    let ratio = 1;
    const screen: any = window.screen;
    // To account for zoom, change to use deviceXDPI instead of systemXDPI
    if (screen.systemXDPI && screen.logicalXDPI && screen.systemXDPI > screen.logicalXDPI) {
      // Only allow for values > 1
      ratio = screen.systemXDPI / screen.logicalXDPI;
    } else if (window.devicePixelRatio !== undefined) {
      ratio = window.devicePixelRatio;
    }
    return ratio;
  }

  static getLineStringBounds(ls: LineString): LngLatBoundsLike {
    const coordinates = ls.coordinates.map(coordinate => new LngLat(coordinate[0], coordinate[1]));
    return UtilService.getBounds(coordinates);
  }

  static getMarkerBounds(markers: Marker[]): LngLatBoundsLike {
    const coordinates = markers.map(marker => marker.getLngLat());
    return UtilService.getBounds(coordinates);
  }

  static getBounds(coordinates: LngLat[]): LngLatBoundsLike {
    let lngMin;
    let lngMax;
    let latMin;
    let latMax;
    coordinates.forEach((lngLat: LngLat) => {
      if (!lngMin || lngLat.lng < lngMin) {
        lngMin = lngLat.lng;
      }
      if (!lngMax || lngLat.lng > lngMax) {
        lngMax = lngLat.lng;
      }
      if (!latMin || lngLat.lat < latMin) {
        latMin = lngLat.lat;
      }
      if (!latMax || lngLat.lat > latMax) {
        latMax = lngLat.lat;
      }
    });
    if (latMin && latMax && lngMin && lngMax) {
      return [[lngMin, latMin], [lngMax, latMax]];
    } else {
      throw new Error(('Could not create bounds from markers.'));
    }
  }

  /**
   * Checking whether coordinates are in the bounding box of Niederrhein
   */
  static inBoundingBox(lngLat: LngLat, bounds: LngLatBounds) {
    const lng = lngLat[0] || lngLat.lng;
    const lat = lngLat[1] || lngLat.lat;
    const sw = bounds[0] || bounds.getSouthWest();
    const ne = bounds[1] || bounds.getNorthEast();
    const lngMin = sw[0] || sw.lng;
    const latMin = sw[1] || sw.lat;
    const lngMax = ne[0] || ne.lng;
    const latMax = ne[1] || ne.lat;
    return lngMin < lng && lng < lngMax && latMin < lat && lat < latMax;
  }

  /**
   * Split given line string by a given point
   */
  static splitLineString(ls: LineString, point: Point) {
    let parts: turf.helpers.FeatureCollection<LineString>;
    if (turf.booleanEqual(this.getFirstPoint(ls), point)) {
      // First point of line string == point
      parts = turf.featureCollection([null, turf.feature(ls)]);
    } else if (turf.booleanEqual(this.getLastPoint(ls), point)) {
      // Last point of line string == point
      parts = turf.featureCollection([turf.feature(ls), null]);
    } else if (this.pointOnLine(point, ls)) {
      // Point is somewhere in the middle of the line string
      const lineStringFeature = turf.lineString(ls.coordinates);
      const pointFeature = turf.point(point.coordinates);
      // parts = turf.lineSplit(lineStringFeature, pointFeature);
      parts = this.lineSplit(lineStringFeature, pointFeature);
      if (parts.features.length === 1 && turf.booleanEqual(ls, parts.features[0].geometry)) {
        // Construct a feature collection of two parts if it has only one part which is the line string to split
        if (turf.distance(this.getFirstPoint(ls), point) <= turf.distance(this.getLastPoint(ls), point)) {
          // Point is probably near the first point of line string
          parts = turf.featureCollection([null, turf.feature(ls)]);
        } else {
          // Point is probably near the last point of line string
          parts = turf.featureCollection([turf.feature(ls), null]);
        }
      }
    } else {
      // Point is nowhere on line
      parts = turf.featureCollection([null, null]);
    }
    if (parts.features.length === 2 && parts.features[0] !== undefined && parts.features[1] !== undefined) {
      return parts;
    } else {
      console.warn(JSON.stringify(parts));
      console.warn('Splitting of line string didn\'t work properly.');
      return undefined;
    }
  }

  /**
   * Split given line string by a given point
   *
   * @param ls - the line string to split
   * @param point - the point at which the line string shall be split
   * @return the parts of the split line string
   */
  static splitLineStringV2NoPointOnLineCheck(ls: LineString, point: Point) {
    let parts: turf.helpers.FeatureCollection<LineString>;
    if (turf.booleanEqual(this.getFirstPoint(ls), point)) {
      // First point of line string == point
      parts = turf.featureCollection([null, turf.feature(ls)]);
    } else if (turf.booleanEqual(this.getLastPoint(ls), point)) {
      // Last point of line string == point
      parts = turf.featureCollection([turf.feature(ls), null]);
    } else {
      // Point is somewhere in the middle of the line string
      const lineStringFeature = turf.lineString(ls.coordinates);
      const pointFeature = turf.point(point.coordinates);
      // parts = turf.lineSplit(lineStringFeature, pointFeature);
      parts = this.lineSplit(lineStringFeature, pointFeature);
    }
    if (parts.features.length === 2 && parts.features[0] !== undefined && parts.features[1] !== undefined) {
      return parts;
    } else {
      console.warn('Splitting of line string didn\'t work properly.');
      return undefined;
    }
  }

  /**
   * Reverses a line string
   *
   * @return lineString The reverses line string
   */
  static reverseLineString(ls: turf.helpers.Feature<LineString>) {
    const reversedCoordinates: number[][] = ls.geometry.coordinates.slice().reverse();
    return turf.lineString(reversedCoordinates);
  }

  /**
   * @returns the last coordinates of the given line string as a point
   */
  static getLastPoint(ls: LineString) {
    const length = ls.coordinates.length;
    const coordinate = ls.coordinates[length - 1];
    return turf.point(coordinate).geometry;
  }

  /**
   * @returns the first coordinates of the given line string as a point
   */
  static getFirstPoint(ls: LineString) {
    const coordinate = ls.coordinates[0];
    return turf.point(coordinate).geometry;
  }

  // static getTheme(): Promise<string> {
  //   return new Promise<string>((resolve, reject) => {
  //     cordova.plugins.ThemeDetection.isAvailable(
  //       (available) => {
  //         if (available && available.value) {
  //           cordova.plugins.ThemeDetection.isDarkModeEnabled(
  //             (enabled) => {
  //               const theme = enabled && enabled.value ? 'light' : 'dark';
  //               resolve(theme);
  //             },
  //             (error) => {
  //               reject();
  //             }
  //           );
  //         } else {
  //           resolve('dark');
  //         }
  //       },
  //       (error) => {
  //         reject();
  //       }
  //     );
  //   });
  // }

  /**
   * Creates a buffer along a line around a given point on that line.
   *
   * @param ls - The line at which the buffer shall be created
   * @param point - A point on or near the line which serves as the center point of the buffer to be calculated
   * @param width - The desired width of the buffer in each direction of the point (given in meters)
   * @param segment - The number of the current segment to support overlapping segments
   * @returns The buffered point as a line string
   */
  static lineBufferV3(ls: LineString, point: Point, width: number, segment: number): turf.helpers.Feature<LineString> {
    // Determine the segment of the route on which the point lies, it becomes the center of the buffer.
    const pointFoundAt: number = UtilService.findPointOnLineString(point, ls, segment);
    if (pointFoundAt !== null) {
      let currentIndexToSegments: number = pointFoundAt;
      // The segments leading to the point. Initialize them with the index of the coordinates on which the point lies.
      let toSegments: GeoJSON.Position[] = [ls.coordinates[pointFoundAt]];
      let toSegmentsLineString: turf.helpers.Feature<LineString>;
      let currentLengthToSegments = 0;
      // Aggregate toSegments.
      while (currentLengthToSegments < width && currentIndexToSegments > 0) {
        currentIndexToSegments--;
        toSegments.unshift(ls.coordinates[currentIndexToSegments]);
        toSegmentsLineString = turf.lineString(toSegments);
        currentLengthToSegments = turf.length(toSegmentsLineString, { units: 'meters' });
      }
      if (toSegmentsLineString != undefined) {
        // Slice line string at width
        // Reverse line string to be able to apply lineSliceAlong(), apply lineSliceAlong(), and flip back.
        const toSegmentsLineStringReversed: turf.helpers.Feature<LineString> = this.reverseLineString(toSegmentsLineString);
        const toSegmentsLineStringReversedAndSliced: turf.helpers.Feature<LineString> = turf.lineSliceAlong(toSegmentsLineStringReversed, 0, width, { units: 'meters' });
        const toSegmentsLineStringSliced: turf.helpers.Feature<LineString> = this.reverseLineString(toSegmentsLineStringReversedAndSliced);
        // Update segments arrays with the sliced line string
        toSegments = toSegmentsLineStringSliced.geometry.coordinates;
      }
      let currentIndexFromSegments: number = pointFoundAt;
      let fromSegments: GeoJSON.Position[] = [ls.coordinates[pointFoundAt]];
      let fromSegmentsLineString: turf.helpers.Feature<LineString>;
      let currentLengthFromSegments = 0;
      // Aggregate fromSegments.
      while (currentLengthFromSegments < width && currentIndexFromSegments < ls.coordinates.length - 1) {
        currentIndexFromSegments++;
        fromSegments.push(ls.coordinates[currentIndexFromSegments]);
        fromSegmentsLineString = turf.lineString(fromSegments);
        currentLengthFromSegments = turf.length(fromSegmentsLineString, { units: 'meters' });
      }
      if (fromSegmentsLineString != undefined) {
        // If a line string was constructed, which is the case for all but the first and the last point
        // Slice line string at width
        const fromSegmentsLineStringSliced: turf.helpers.Feature<LineString> = turf.lineSliceAlong(fromSegmentsLineString, 0, width, { units: 'meters' });
        // Update segments arrays with the sliced line string
        fromSegments = fromSegmentsLineStringSliced.geometry.coordinates;
      }
      // Remove common coordinate from one of the arrays.
      toSegments.pop();
      // Concatenate the two arrays.
      const lineBufferCoordinates: number[][] = toSegments.concat(fromSegments);
      const lineBuffer: turf.helpers.Feature<LineString> = turf.lineString(lineBufferCoordinates);
      return lineBuffer;
    }
    return null;
  }

  /**
   * Creates a buffer along a line around a given point on that line.
   *
   * @param ls - The line at which the buffer shall be created
   * @param point - A point on or near the line which serves as the center point of the buffer to be calculated
   * @param width - The desired width of the buffer in each direction of the point (given in meters)
   * @param segment - The number of the current segment to support search on line strings with overlapping segments
   * @returns The buffered point as a line string
   */
  static lineBufferV4(ls: LineString, point: Point, width: number, segment: number): turf.helpers.Feature<LineString> {
    // Determine the segment of the route on which the point lies, it becomes the center of the buffer
    const centerCoordinatesIndex: number = UtilService.findSegmentOnLineString(point, ls, segment);
    // First and last segment needs special handling to prevent buffer from growing beyound the route
    let buffer: turf.helpers.Feature<LineString>;
    if (centerCoordinatesIndex == 0) {
      buffer = turf.lineString([ls.coordinates[0], ls.coordinates[1]]);
    } else if (centerCoordinatesIndex == ls.coordinates.length - 2) {
      buffer = turf.lineString([ls.coordinates[centerCoordinatesIndex], ls.coordinates[centerCoordinatesIndex + 1]]);
    } else {
      buffer = turf.lineString([ls.coordinates[centerCoordinatesIndex], ls.coordinates[centerCoordinatesIndex + 1], ls.coordinates[centerCoordinatesIndex + 2]]);
    }
    return buffer;
  }

  /**
   * Calculates the distance of two points on a segment of a line string
   *
   * @param pointA
   * @param pointB
   * @param lineStr
   */
  static distanceOnLineSegment(pointA: Point, pointB: Point, lineStr: LineString, segmentNumber: number) {
    const segmentStartsAt = turf.point(lineStr.coordinates[segmentNumber]);
    if (turf.distance(pointA, segmentStartsAt, { units: 'meters' }) < turf.distance(pointB, segmentStartsAt, { units: 'meters' })) {
      return turf.distance(pointA, pointB, { units: 'meters' });
    } else {
      return turf.distance(pointA, pointB, { units: 'meters' }) * -1;
    }
  }


  /**
   * Calculates the distance from point A to point B on a line string.
   * Consider relative position on that line string by defining distance reaching backwards on the line string as negative.
   * The start segment is used to indicate position in line string in the case of overlapping segments.
   *
   * The algorithm works as following:
   * 1. Find segment of line string on which point A lies
   * 2. Find segment of line string on which point B lies
   * 3. Construct new line string by concatenating
   *  a) Line from point A to the end of the segment where point A was found
   *  b) A sliced line string of the route line string, which
   *      starts with the segment where point A was found + 1 and
   *      ends with (including) the segment where point B was found -1
   *  c) Line from the last point of the sliced line string to point B
   * 4. Return length of constructed line string
   * 5. Determine sign of that distance
   *
   *
   * @param pointA - a point somewhere on the line string
   * @param pointB - a point somewhere on the line string
   * @param route - route
   * @param startSearchAt - start search at this segment number
   */
  static distanceOnLineV2(pointA: Point, pointB: Point, route: LineString, startSearchAt: number) {
    const routeCoords: number[][] = route.coordinates;
    if (startSearchAt < routeCoords.length) {
      // Find route segments for point A and point B
      const fromSegment: number = UtilService.findSegmentOnLineString(pointA, route, startSearchAt);
      const toSegment: number = UtilService.findSegmentOnLineString(pointB, route, startSearchAt);
      const slicedSegments: number[][] = routeCoords.slice(fromSegment + 1, toSegment);
      // Add point A at the beginning of the array with the sliced segments
      slicedSegments.unshift(pointA.coordinates);
      // Add point B at the end of the array with the sliced segments
      slicedSegments.push(pointB.coordinates);
      const slicedSegmentsWithMarginsLineString = turf.lineString(slicedSegments);
      if (fromSegment < toSegment) {
        return turf.length(slicedSegmentsWithMarginsLineString, { units: 'meters' });
      } else if (fromSegment === toSegment) {
        //return this.distanceOnLine(pointA, pointB, route)
        return this.distanceOnLineSegment(pointA, pointB, route, fromSegment);
      } else if (fromSegment > toSegment) {
        return turf.length(slicedSegmentsWithMarginsLineString, { units: 'meters' }) * -1;
      }
    } else {
      throw new Error('Segment number too large to start search from.');
    }
  }


  /**
   * Returns true if a point is on a line.
   * A point is also considered as on the line if the distance to the line is less than 1 cm.
   */
  static pointOnLine(point: Point, line: turf.helpers.LineString) {
    return turf.pointToLineDistance(point, line, { units: 'meters' }) < 1.0;
  }

  /**
   *
   * @param point Find point on route segment
   * @param routeCoords - The array of route coordinates
   * @param segment - The index of the segment in the coordinates array
   * @returns Segment number if found, null otherwise
   */
  static findPointOnRouteSegment(point: Point, routeCoords: number[][], segment: number): number {
    const segmentCoordinates: number[][] = routeCoords.slice(segment, segment + 2);
    const lineSegment = turf.lineString(segmentCoordinates, { name: 'line segment' }).geometry;
    if (this.pointOnLine(point, lineSegment)) {
      return segment;
    } else {
      return null;
    }
  }


  /**
   * Find a segment on a line string, starting from a certain segment of that line string.
   * Search direction is backwards and then forwards of that segment, segment by segment.
   * Search stops at first occurence of a point searched for.
   *
   * @param point - the point to search for
   * @param route - the line string on which the point is searched
   * @param pivotSegment - the number of the segment from where the search in both directions starts
   * @returns the index of the segment where the point was first found, or null if not found
   */
  static findSegmentOnLineString(point: Point, route: LineString, pivotSegment: number): number {
    const lineStringCoords: number[][] = route.coordinates;
    // Initialize variables for found segment and margins
    let foundSegment: number = null;
    // Initialize margins
    let lowerMargin: number = pivotSegment;
    let upperMargin: number = pivotSegment;
    // Search starts at a certain pivot segment
    foundSegment = this.findPointOnRouteSegment(point, lineStringCoords, pivotSegment);
    // If not accidently found at this segment..
    if (foundSegment === null) {
      do {
        // Decrement left margin segment if possible
        if (lowerMargin > 0) {
          lowerMargin--;
          // and search on lower margin segment
          foundSegment = this.findPointOnRouteSegment(point, lineStringCoords, lowerMargin);
        }
        // If still not found, increment upper margin segment if possible
        if (foundSegment === null && upperMargin < lineStringCoords.length - 2) {
          upperMargin++;
          // and search on upper margin segment
          foundSegment = this.findPointOnRouteSegment(point, lineStringCoords, upperMargin);
        }
      } while (foundSegment === null && (lowerMargin > 0 || upperMargin < lineStringCoords.length - 2));
    }
    if (foundSegment !== null) {
      //console.log('Found point ' + point.coordinates + ' on segment ' + foundSegment + ' of route.');
    } else {
      console.debug('Didn\'t find point ' + JSON.stringify(point) + 'anywhere on line string ' + JSON.stringify(route));
    }
    return foundSegment;
  }

  /**
   * Find a point on a line string, starting from a certain segment of that line string.
   * Use {@link findSegmentOnLineString} to find the segment number first and then compare distances
   * from the searched point to the start and to the end of the segment to determine the nearest coordinate.
   *
   * @param point
   * @param route
   * @param pivotSegment
   * @returns the nearest coordinate in the array of route coordinates
   */
  static findPointOnLineString(point: Point, route: LineString, pivotSegment: number): number {
    const segmentNr = this.findSegmentOnLineString(point, route, pivotSegment);
    const distPointSegmentStart: number = turf.distance(point, turf.point(route.coordinates[segmentNr]));
    const distPointSegmentEnd: number = turf.distance(point, turf.point(route.coordinates[segmentNr + 1]));
    if (distPointSegmentStart < distPointSegmentEnd) {
      return segmentNr;
    } else if (segmentNr < route.coordinates.length - 1) {
      return segmentNr + 1;
    } else {
      console.log('findPointOnLineString: Unexpected result');
    }
  }

  /**
     * Rearranges array so that the new array is a composite of
     * the elements from startIndex to end [i..n]
     * plus the elements starting from the beginnung to startIndex-1 [0..i-1]
     */
  static rearrangeArray(array: any[], startIndex: number): any[] {
    var firstPart = array.slice(startIndex, array.length);
    var secondPart = array.slice(0, startIndex);
    return firstPart.concat(secondPart);
  }


  static getDateTimeString(date?: Date): string {
    const addLeadingZeros = (numstr: any, zeros = 1) => {
      numstr = numstr.toString();
      while (numstr.length < zeros) {
        numstr = '0' + numstr;
      }
      return numstr;
    };

    date = date || new Date();
    let dateString = date.getFullYear().toString();
    dateString += addLeadingZeros(date.getMonth() + 1);
    dateString += addLeadingZeros(date.getDate());
    dateString += '_' + addLeadingZeros(date.getHours());
    dateString += addLeadingZeros(date.getMinutes());
    dateString += addLeadingZeros(date.getSeconds());
    dateString += addLeadingZeros(date.getMilliseconds(), 2);

    return dateString;
  }

  /**
   * Split a line string by a point.
   * Manually build parts of the line strings if line string coordinates contain point coordinates,
   * otherwise use Turf.
   *
   * @param lineStringFeature
   * @param pointFeature
   * @returns
   */
  static lineSplit(lineStringFeature: turf.helpers.Feature<LineString>, pointFeature: turf.helpers.Feature<Point>) {
    const lineStringCoords: number[][] = lineStringFeature.geometry.coordinates;
    const position: number = this.indexOf(lineStringFeature, pointFeature);
    if (position > -1) {
      // Found coordinate: Build manually
      const part1: turf.helpers.Feature<LineString> = turf.lineString(lineStringCoords.slice(0, position + 1));
      const part2: turf.helpers.Feature<LineString> = turf.lineString(lineStringCoords.slice(position));
      const features: turf.helpers.FeatureCollection<LineString> = turf.featureCollection([part1, part2]);
      return features;
    } else { // Not found: Use Turf
      return turf.lineSplit(lineStringFeature, pointFeature);
    }
  }

  /**
   * Checks if a point coordinates array is contained in a line strings array.
   *
   * @return position of the first occurrence or -1
   */
  static indexOf(lineStringFeature: turf.helpers.Feature<LineString>, pointFeature: turf.helpers.Feature<Point>): number {
    const lineStringCoords: number[][] = lineStringFeature.geometry.coordinates;
    const pointCoords: number[] = pointFeature.geometry.coordinates;
    for (let i = 0; i < lineStringCoords.length; i++) {
      if (lineStringCoords[i][0] === pointCoords[0] && lineStringCoords[i][1] === pointCoords[1]) {
        return i;   // Found it
      }
    }
    return -1; // Not found it
  }


  static removeElementFromArray(array: any[], id: string) {
    const index = array.findIndex((element) => element.id === id);
    if (index !== -1) {
      array.splice(index, 1);
    }
    return array;
  }


  /**
   * Pause an already existing HTML audio element,
   * create a new audio element and play the linked audio file,
   *
   * @param path - the path of the audio element to play
   * @param audio - the possibly existing audio element
   * @returns the updated audio element
   */
  static playAudio(path: string, audio?: HTMLAudioElement) {
    if (audio) {
      audio.pause();
    }
    audio = new Audio(path);
    audio.play();
    return audio;
  }

  static locationInBounds(position?: Position, lngLat?: LngLat) {
    if (position) {
      lngLat = new LngLat(position.coords.longitude, position.coords.latitude);
    }
    if (lngLat) {
      const feature: turf.Feature<turf.Point> = {
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [lngLat.lng, lngLat.lat]
        },
        properties: {}
      };
      const polygon: turf.Polygon = {
        type: 'Polygon',
        coordinates: [
          [
            [Constants.MIN_LNG, Constants.MIN_LAT],
            [Constants.MIN_LNG, Constants.MAX_LAT],
            [Constants.MAX_LAT, Constants.MAX_LAT],
            [Constants.MAX_LAT, Constants.MIN_LAT],
            [Constants.MIN_LNG, Constants.MIN_LAT]
          ]
        ]
      };
      const points: turf.FeatureCollection<turf.Point> = turf.pointsWithinPolygon(feature, polygon);
      return points.features.length > 0;
    } else {
      return null;
    }
  }

  getMonth(monthNumber): any {
    return new Promise(resolve => {
      this.translate.get('months.' + monthNumber).subscribe(value => {
        resolve(value);
      });
    });
  }

  getViewId(name: string): string {
    name = name.split('/')[0];
    if (this.pageSegments.hasOwnProperty(name)) {
      return this.pageSegments[name];
    } else {
      return name;
    }
  }

  getTimeFromDistance(distance: number, speed: number = 15): string {
    if (distance <= 5000) {
      distance = Math.round(distance / 100) / 10; // higher accuracy for shorter routes
    } else {
      distance = Math.round(distance / 1000);
    }
    const time = distance / speed;
    const hours = Math.floor(time);
    const minutes = Math.round((time - hours) * 12) * 5; // round by 5 minutes
    let timeString = '';
    if (hours === 0) {
      timeString = '00:';
    } else {
      if (hours < 10) {
        timeString = '0';
      }
      timeString += hours + ':';
    }
    if (minutes < 10) {
      timeString += '0';
    }
    timeString += minutes;
    return timeString;
  }

  async showFerryPopup() {
    let ferryMsg = '';
    let ferryTitle = '';
    this.translate.get('services.util.ferry-message').subscribe(value => {
      ferryMsg = value;
    });
    this.translate.get('services.util.ferry-title').subscribe(value => {
      ferryTitle = value;
    });
    const alert = await this.alertCtrl.create({
      header: ferryTitle,
      message: ferryMsg,
      buttons: ['OK']
    });
    await alert.present();
  }


  async handleErrorMsg(error) {
    if (!this.alert) {
      let errorMsg = '';
      let errorTitle = '';
      let errorSubTitle = '';
      let noConnectionError = '';
      this.translate.get('services.util.loader-text').subscribe(value => {
        errorMsg = value;
      });
      this.translate.get('services.error.title').subscribe(value => {
        errorTitle = value;
      });
      this.translate.get('services.error.subTitle').subscribe(value => {
        errorSubTitle = value;
      });
      this.translate.get('services.error.no-connection-error').subscribe(value => {
        noConnectionError = value;
      });
      if (typeof error === 'string') {
        errorMsg = error;
      } else if (error.message) {
        errorMsg = error.message;
      } else if (error.resultText) {
        errorMsg = error.resultText;
      }
      if (errorMsg === 'Http failure response for (unknown url): 0 Unknown Error') {
        errorMsg = noConnectionError;
      }
      this.alert = await this.alertCtrl.create({
        header: errorTitle,
        message: errorSubTitle + errorMsg,
        buttons: ['OK']
      });
      await this.alert.present();
      await this.alert.onDidDismiss();
      this.alert = null;
    }
  }

  async handleServerError(response: ErrorResponse) {
    if (!this.alert) {
      this.alert = await this.alertCtrl.create({
        header: response.error,
        message: response.message,
        buttons: ['OK']
      });
      await this.alert.present();
      await this.alert.onDidDismiss();
      this.alert = null;
    }

  }

  /**
   * There are several images in the Media Array. Only one image has the attribute 'Highlight'.
   * The url from the image with the attribute 'Highlight' is returned.
   */
  getHighlightImage(media: Media[], size: string = '450x253c'): string {
    // retrieve highlight image
    if (media) {
      let image = media.find((medium: Media) => medium.usage === 'HIGHLIGHT');
      if (!image) {
        // if no highlight image was found, use first gallery image
        image = media.find((medium: Media) => medium.usage === 'GALLERY');
      }
      if (image && image.url) {
        // handle relative urls
        if (!image.url.match(/^(https?:\/\/|www\.).*?$/)) {
          image.url = Constants.URL_BASE_MEDIA + image.url;
        }
        return image.url + '?size=' + size;
      } else {
        return null;
      }
    } else {
      return null;
    }
  }

  getTranslation(translatable: Translatable): Translation {
    if (translatable && translatable.localized[this.translate.currentLang]) {
      // preventing accidental hyphen at the end of categories and (node) descriptions
      const translated = translatable.localized[this.translate.currentLang];
      if (translated.function && translated.function[translated.function.length - 1] === '-') {
        translated.function = translated.function.slice(0, -1);
      }
      if (translated.description && translated.description[0] === '-') {
        translated.description = translated.description.slice(1);
      }
      return translated;
    } else if (translatable && translatable.localized[this.translate.defaultLang]) {
      const translated = translatable.localized[this.translate.defaultLang];
      if (translated.function && translated.function[translated.function.length - 1] === '-') {
        translated.function = translated.function.slice(0, -1);
      }
      if (translated.description && translated.description[0] === '-') {
        translated.description = translated.description.slice(1);
      }
      return translated;
    } else {
      return {
        name: '',
        teaser: '',
        description: '',
        accessibleinfo: '',
        tags: '',
        fee: ''
      };
    }
  }

  /**
   * An overlay that can be used to indicate activity while blocking user interaction.
   */
  async showLoadingBar() {
    if (!this.loadingSpinner) {
      const message = await firstValueFrom(this.translate.get('services.util.loader-text'));
      this.loadingSpinner = await this.loadingCtrl.create({
        message: message + '…'
      });
      await this.loadingSpinner.present();
    }
  }

  /**
   * To dismiss the loading indicator after creation, call the dismiss() method on the Loading instance.
   */
  async dismissLoadingBar() {
    if (this.loadingSpinner) {
      await this.loadingSpinner.dismiss();
      this.loadingSpinner = null;
    }
  }

  /**
   * HTML or MsOffice tags are removed from the strings
   */
  parserTags(rawValue: string): string {
    if (rawValue) {
      return rawValue
        .replace(/&lt;/g, '<')
        .replace(/&gt;/g, '>')
        .replace(/&amp;/g, '&')
        .replace(/&quot;/g, '"');
    } else {
      return '';
    }
  }

  sortBy(a: any[], property: string, direction: string = 'ASC'): any[] {
    const dirMod = direction === 'ASC' ? 1 : -1;
    return a.sort((x, y) => {
      if (x[property] < y[property]) {
        return -1 * dirMod;
      } else if (x[property] > y[property]) {
        return dirMod;
      } else {
        return 0;
      }
    });
  }

  /**
   * Calculates the distance between two points
   *
   * @param lng1 longitude point 1
   * @param lat1 latitude point 1
   * @param lng2 longitude point 2
   * @param lat2 latitude point 2
   */
  getDistance(lng1, lat1, lng2, lat2): number {
    const deg2rad = (deg): number => deg * (Math.PI / 180);

    const R = 6371; // Radius of the earth in km
    const dLat = deg2rad(lat2 - lat1);  // deg2rad below
    const dLon = deg2rad(lng2 - lng1);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) *
      Math.sin(dLon / 2) * Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return R * c; // Distance in km
  }

  findClosestPolylinePoint(point, polyline) {
    const distSquared = (pt1, pt2) => {
      const diffX = pt1.lng - pt2[0];
      const diffY = pt1.lat - pt2[1];
      return (diffX * diffX + diffY * diffY);
    };

    let destinations;
    if (polyline.source && polyline.source.data) {
      destinations = polyline.source.data.coordinates;
    } else {
      destinations = polyline.geom.coordinates;
    }
    let closest = destinations[0];
    let polylineIndex;
    let shortestDistance = distSquared(point, destinations[0]);
    destinations.forEach((destination, index) => {
      const d = distSquared(point, destination);
      if (d < shortestDistance) {
        closest = destination;
        shortestDistance = d;
        polylineIndex = index;
      }
    });
    return { point: { lng: closest[0], lat: closest[1] }, index: polylineIndex };
    // closest;
  }

  async showToast(mouseoverText: string) {
    const duration = 2000;
    const message = await firstValueFrom(this.translate.get(mouseoverText));
    const toast = await this.toastCtrl.create({ message, duration });
    await toast.present();
  }
}
