import {Injectable} from '@angular/core';
import {Observable, Subject, Subscription} from 'rxjs';

/**
 * An event encapsulates a Subject a derived Observable and an identifying title string.
 */
export class Event {
  topic: string;
  subject: Subject<any>;
  observable: Observable<any>;
}

@Injectable({
  providedIn: 'root'
})
export class EventsService {

  private events: Event[] = [];

  /**
   * Fires a named event, passing along optional data.
   *
   * @param topic unique title for the event
   * @param args params to pass to a callback
   */
  publish(topic: string, ...args: any[]) {
    let associatedEvent = this.events.find(event => event.topic === topic);
    if (!associatedEvent) {
      associatedEvent = this.createEvent(topic);
    }
    // dismantle array in case there's only one entry
    const value: any = args.length === 1 ? args[0] : args;
    associatedEvent.subject.next(value);
  }

  /**
   * Subscribes to a named event by providing a callback function.
   * Returns a Subscription which can be used to unsubscribe from the event.
   * @param topic unique title for the event
   * @param handler callback function to be executed when given event is published
   */
  subscribe(topic: string, handler: (data?: any) => any): Subscription {
    let associatedEvent = this.events.find(event => event.topic === topic);
    if (!associatedEvent) {
      associatedEvent = this.createEvent(topic);
    }
    return associatedEvent.observable.subscribe(handler);
  }

  /**
   * Creates a new event
   * @param topic unique title for the event
   */
  private createEvent(topic: string): Event {
    const subject = new Subject();
    const observable = subject.asObservable();
    const count = this.events.push({topic, subject, observable});
    return this.events[count - 1];
  }
}
