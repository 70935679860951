import {Component, HostListener, NgZone, ViewChild} from '@angular/core';
import {registerLocaleData} from '@angular/common';
import localeDe from '@angular/common/locales/de';

import {AlertController, MenuController, Platform, PopoverController, ToastController} from '@ionic/angular';
import {TranslateService} from '@ngx-translate/core';
import {EventsService} from './services/events.service';
import {NavigationEnd, Router} from '@angular/router';
import {MenuItem} from './lib/menu-item';
import {MenuItems} from './var/menu-items';

import {filter} from 'rxjs/operators';
import {UserService} from './services/user.service';
import {ToolbarSearchbarComponent} from './components/toolbar-searchbar/toolbar-searchbar.component';
import {GlobalsService} from './services/globals.service';
import {LngLat, Marker} from 'maplibre-gl';
import {MapMenuComponent} from './components/map-menu/map-menu.component';
import {User} from './lib/types/radrevier-ruhr';
import {StorageService} from './services/storage.service';
import {MapService} from './services/map.service';

import {App, URLOpenListenerEvent} from '@capacitor/app';
import {Capacitor} from '@capacitor/core';
import {SplashScreen} from '@capacitor/splash-screen';
import {StatusBar, Style} from '@capacitor/status-bar';
import {PositionService} from './services/position.service';
import {TrackingService} from './services/tracking.service';
import {SettingsService} from './services/settings.service';
import {firstValueFrom} from 'rxjs';

export function isWebglSupported() {
  if (window.WebGLRenderingContext) {
    const canvas = document.createElement('canvas');
    try {
      // Note that { failIfMajorPerformanceCaveat: true } can be passed as a second argument
      // to canvas.getContext(), causing the check to fail if hardware rendering is not available. See
      // https://developer.mozilla.org/en-US/docs/Web/API/HTMLCanvasElement/getContext
      // for more details.
      const context = canvas.getContext('webgl2') || canvas.getContext('webgl');
      if (context && typeof context.getParameter == 'function') {
        return true;
      }
    } catch (e) {
      // WebGL is supported, but disabled
    }
    return false;
  }
  // WebGL not supported
  return false;
}


@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  @ViewChild('searchBarHeader') searchBar: ToolbarSearchbarComponent;
  /**
   * MenuItems to be shown as tiles, in the top section of the side menu (mobile) or the desktop header
   */
  public menuItemsPrimary: MenuItem[] = MenuItems.primary;
  public menuItemsPrimarySide: MenuItem[] = MenuItems.primary.filter(item => !!item.icon);
  public menuItemsPrimaryHeader: MenuItem[] = MenuItems.primary.filter(item => !!item.img);
  /**
   * MenuItems to be shown in the lower section of the side menu (mobile) or in the user menu (desktop)
   */
  public menuItemsSecondary: MenuItem[] = MenuItems.secondary;
  /**
   * Whether the app is running on a mobile device
   */
  public mobile: boolean;
  /**
   * Set to false to hide the header
   */
  public header = true;
  /**
   * For small landscape devices a slim header is used to save space
   */
  public slimHeader = false;
  /**
   * The current inner width of the window
   */
  currentInnerWidth: number;
  /**
   * Indicates if the map should be open in desktop mode
   */
  mapVisible = false;
  /**
   * The apps currently selected language (ISO 639-1, one of ['de', 'en', 'nl'])
   */
  language: string;
  /**
   * Language to be used as a fallback when a translation isn't found in the selected language
   */
  defaultLanguage: 'de';
  /**
   * Names of pages that require the map to be open in desktop mode
   */
  mapPages: string[] = [
    '/haltestelle',
    '/ort-details',
    '/orte',
    '/tourenplaner',
    '/tour-details',
    '/touren',
    '/favoriten',
    '/eigene-touren'
  ];
  /**
   * WebGl support in browser
   */
  webGlSupported: boolean = isWebglSupported();
  /**
   * Currently active side menu
   */
  activeMenu: string;

  /**
   * Currently logged in user
   */
  user: User;

  constructor(
    public platform: Platform,
    private alertCtrl: AlertController,
    private events: EventsService,
    private globals: GlobalsService,
    private mapService: MapService,
    private menuCtrl: MenuController,
    private popoverCtrl: PopoverController,
    private positionService: PositionService,
    private router: Router,
    private settings: SettingsService,
    private storage: StorageService,
    private trackingService: TrackingService,
    private translate: TranslateService,
    private toastCtrl: ToastController,
    private userService: UserService,
    private zone: NgZone
  ) {
    // replacing urls containing hashes
    if (location.href.match(/^(.*)?\/#\/(.*)$/)) {
      window.location.replace(location.href.replace(/\/#/, ''));
    }

    this.initializeApp().then(() => console.log('initialized app'));
  }

  get mapMenu() {
    return this.globals.get('mapMenu');
  }

  @ViewChild(MapMenuComponent) set mapMenu(menu: MapMenuComponent) {
    menu.refresh();
    this.globals.set('mapMenu', menu);
  }

  /**
   * Currently active route
   */
  get currentRoute() {
    return this.globals.get('currentRoute', '/start');
  }

  set currentRoute(route) {
    this.globals.set('currentRoute', route);
  }

  @HostListener('window:resize', ['$event'])
  async onResize(event) {
    // set actual screen size
    this.currentInnerWidth = event.target.innerWidth;
    // checks wether the map is visible or not
    this.setMapVisibility();
    await this.setActiveMenu();
    this.slimHeader = window.innerWidth >= 1280 && window.innerHeight < 830 || window.innerHeight < 730;
  }

  async initializeApp() {
    App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
      console.log('app url open');
      this.zone.run(async () => {
        // Example url: https://beerswift.app/tabs/tab2
        // slug = /tabs/tab2
        let slug: string = event.url.split('.ruhr').pop();
        slug = slug.replace('/#', ''); // in case there's an old link somewhere
        if (slug) {
          console.log('full url', slug);
          const fragments = slug.split('/');
          console.log('fragmented url', fragments);
          if (fragments[1] === 'tour-details') {
            await this.router.navigate([slug], {queryParams: {tourId: fragments[2], userTour: false}});
          } else {
            await this.router.navigateByUrl(slug);
          }
        }
        console.log('didnt match to anything');
      });
    });

    this.currentInnerWidth = window.innerWidth;

    this.setHeaderSize();

    // save current route in variable each time NavigationEnd event is triggered
    this.router.events.pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: any) => {
        this.currentRoute = event.url;
        this.setMapVisibility();
      });

    // subscribe to search complete event
    this.events.subscribe('search:complete', () => {
      if (this.searchBar) {
        this.searchBar.loading = false;
      }
    });

    // subscribe to user login change
    this.events.subscribe('user:login-change', (user: User) => {
      this.user = user;
    });

    // subscribe to menu changes
    this.events.subscribe('set-menu', (menu: 'map' | 'main') => {
      this.setActiveMenu(menu);
    });

    // subscribe to map control events
    this.events.subscribe('poi-control:toggle', async () => {
      await this.menuCtrl.toggle(this.activeMenu);
      const menu = await this.menuCtrl.get(this.activeMenu);
      const placesListEl = menu.getElementsByClassName('places-list')[0];
      placesListEl.scrollIntoView({behavior: 'smooth', block: 'start'});
    });

    this.events.subscribe('wms-layer-control:toggle', async () => {
      await this.menuCtrl.toggle(this.activeMenu);
      const menu = await this.menuCtrl.get(this.activeMenu);
      const layersListEl = menu.getElementsByClassName('layers-list')[0];
      layersListEl.scrollIntoView({behavior: 'smooth', block: 'start'});
    });

    // this language will be used as a fallback when a translation isn't found in the current language
    this.translate.setDefaultLang('de');
    await this.setLanguage('de', false);

    // try to get language from settings
    /*
    const storedLang = await this.storage.get('set-language');
    if (storedLang && storedLang !== 'auto') {
        await this.setLanguage(storedLang);
    } else {
        // try to get language from device
        if (navigator.language) {
            const browserLang = navigator.language.split('-')[0];
            if (['de', 'en', 'nl'].indexOf(browserLang) !== -1) {
                // change to browserLang when requested
                await this.setLanguage(browserLang, false);
            } else {
                // else use german
                await this.setLanguage('de', false);
            }
        } else {
            // else use german
            await this.setLanguage('de', false);
        }
    }
    */

    registerLocaleData(localeDe, 'de');

    // restore user information from local db
    this.user = await this.userService.getUser();

    await this.platform.ready();
    await this.setActiveMenu();
    if (Capacitor.getPlatform() !== 'ios' && Capacitor.isPluginAvailable('StatusBar')) {
      // let status bar overlay webview
      await StatusBar.setOverlaysWebView({overlay: false});
      // set status bar to white
      await StatusBar.setBackgroundColor({color: '#505c60'});
      await StatusBar.setStyle({style: Style.Dark});
    }
    if (Capacitor.isPluginAvailable('SplashScreen')) {
      await SplashScreen.hide();
    }

    /* --- matomo tracking --- */
    console.log('matomo: checking dnt status');
    const dnt = !!await this.trackingService.isDNTEnabled();

    console.log('matomo: checking tracking consent status');
    let trackingConsent = await this.settings.get('matomo-tracking-consent');
    if (trackingConsent === undefined) {
      trackingConsent = !dnt;
      await this.settings.set('matomo-tracking-consent', trackingConsent);
    }

    console.log('matomo: checking cookie consent status');
    let cookieConsent = await this.settings.get('matomo-cookie-consent');
    if (cookieConsent === undefined) {
      if (!dnt) {
        cookieConsent = await this.trackingService.askForCookieConsent();
      } else {
        cookieConsent = false;
      }
      await this.settings.set('matomo-cookie-consent', cookieConsent);
    }
    await this.trackingService.setTracking(trackingConsent, cookieConsent);

    /* --- matomo tracking --- */

    const popoverHeader = await firstValueFrom(this.translate.get('app.alert.header'));
    const popoverMessage = await firstValueFrom(this.translate.get('app.alert.message'));
    const popoverBtnDismiss = await firstValueFrom(this.translate.get('shared.continue'));

    const alert = await this.alertCtrl.create({
      header: popoverHeader,
      message: popoverMessage,
      buttons: [{
        role: 'cancel',
        text: popoverBtnDismiss
      }]
    });

    await alert.present();
  }

  /**
   * Set current language, optionally save in local storage
   *
   * @param lang Language to be used in application
   * @param store Whether to save set language in local storage
   */
  async setLanguage(lang: string, store: boolean = true) {
    // tell translate service to use new language
    console.log('will use language', lang);
    this.translate.use(lang);
    // set local language variable
    this.language = lang;
    // finally set html element lang property
    document.documentElement.lang = lang;
    if (store) {
      // store used language
      await this.storage.set('set-language', lang);
    }
    await this.events.publish('language:change');
  }

  async setActiveMenu(forceMenu?: 'map' | 'main') {
    console.log('set active menu', forceMenu);
    const headerVisible = this.globals.get('headerVisible') !== false;
    this.header = headerVisible;
    console.log(headerVisible);
    if (headerVisible && this.currentInnerWidth < 1024) {
      if (forceMenu === 'map' || !forceMenu && (this.currentRoute === '/karte' || this.currentRoute === '/tourenplaner')) {
        this.activeMenu = 'map-menu';
        await this.menuCtrl.enable(false, 'main-menu');
        await this.menuCtrl.enable(true, 'map-menu');
      } else {
        this.activeMenu = 'main-menu';
        await this.menuCtrl.enable(true, 'main-menu');
        await this.menuCtrl.enable(false, 'map-menu');
      }
    } else {
      this.activeMenu = null;
      await this.menuCtrl.enable(false, 'main-menu');
      await this.menuCtrl.enable(false, 'map-menu');
    }
  }

  /**
   * Whether you see the map depends on two factors:
   *  - The map is only visible if the screen size is bigger than 1023px
   *  - The map only appears, when a particular page has been opened
   */
  setMapVisibility() {
    if (this.currentRoute && this.currentRoute !== '') {
      console.log('view entering: ' + this.currentRoute);
      // this.mapVisible = this.mapPages.indexOf(view.component.name) !== -1 && this.currentScreenSize > 1023;
      this.mapVisible = this.currentInnerWidth >= 1024 && !!this.mapPages.find(page => this.currentRoute.match(page));
      console.log('view in mapPages: ' + (this.mapPages.indexOf(this.currentRoute) !== -1));
      console.log('screen size: ' + this.currentInnerWidth);
      this.globals.set('mapVisible', this.mapVisible);
      console.log('set mapVisible: ' + this.mapVisible);
      if (this.currentRoute.includes('/orte') || (this.currentRoute.includes('/touren') && this.currentRoute !== '/tourenplaner')) {
        setTimeout(() => {
          if (this.currentRoute.includes('/orte')) {
            const waypointInput = (<HTMLInputElement>document.getElementsByTagName('app-waypoint-input').item(document.getElementsByTagName('app-waypoint-input').length-1));
            const waypointValue = (<HTMLInputElement>waypointInput.children[0].children[0]).value;
            if (this.mapVisible && waypointValue.length > 0) {
              this.mapService.addTargetLocation(null, true);
              // const radius = document.getElementsByTagName('ion-range')[0].value;
              this.events.publish('target-search:change-poi');
            }
          } else {
          const highlights = this.currentRoute.includes('highlights');
          this.settings.get('TOUR-radius').then((value) => {
            this.settings.get('TOUR-location').then((location) => {
              if (!!value) {
                if (!location) {
                  location = {label: '', coordinates: null};
                }
                this.events.publish('target-search:change-tours', {radius: value, location: location, highlights: highlights});
              }
              // if (!location.coordinates) {
                // this.mapService.removeTargetLocation(); // TODO Where is ist created?
              // }
              // if (!!location) {
              //   this.events.publish('target-search:change-location', {value: location, highlights: highlights});

              // }
              // userLocationTarget = location;
              // console.log('GOT location and target', userRadius, userLocationTarget);

              // if (userLocationTarget !== null) {
              //   const waypointInput = (<HTMLInputElement>document.getElementsByTagName('app-waypoint-input').item(document.getElementsByTagName('app-waypoint-input').length-1));
              //   (<HTMLInputElement>waypointInput.children[0].children[0]).value = userLocationTarget.label;
              // }
              // if (userRadius !== null) {
              //   document.getElementsByTagName('ion-range')[0].value = userRadius;
              // }
            });
          });
        }

        }, 1000);
      }
    }
  }

  async navigate(routerLink: string, navParams?: any) {

    if (this.isActiveRoute(routerLink)) {
      routerLink = '/start';
    }
    if (navParams) {
      await this.router.navigate([routerLink], {queryParams: navParams});
    } else {
      await this.router.navigate([routerLink]);
    }
    await this.menuCtrl.close('main-menu');
  }

  async showUserPopover(event: MouseEvent) {
    await this.userService.showUserPopover(event);
  }

  /**
   * Callback for the button in the info popup (on desktop)
   */
  onPointInfoClick(args: { lngLat: LngLat; marker: Marker; markerInfo: any; event: MouseEvent }) {
    if (this.mapVisible) {
      this.events.publish('routing:point-info', [args]);
    }
  }

  /**
   * Passes search request to searchProvider
   *
   * @param val Value searched for
   */
  search(val: string) {
    if (val && val !== '') {
      this.searchBar.loading = true;
    }
    this.events.publish('search:changed', val);
  }

  /**
   * Checks if a routerLink is equal to the currently active route.
   */
  isActiveRoute(routerLink: string): boolean {
    if (this.currentRoute && this.currentRoute.length > 1) {
      const baseRoute = this.currentRoute.split('/')[1];
      // regex is necessary as e.g. '/touren' and '/tourenplaner' would match '/touren' otherwise
      const regex = new RegExp(`^/${baseRoute}(?:$|/)`);
      return !!routerLink.match(regex);
    } else {
      return false;
    }
  }

  async onMapMenuWillOpen() {
    if (!this.mapMenu.poiCategories || this.mapMenu.poiCategories.length === 0) {
      await this.mapMenu.refresh();
    }
  }

  private setHeaderSize() {
    this.slimHeader = window.innerWidth >= 2048 && window.innerHeight < 950
      || window.innerWidth >= 1280 && window.innerHeight < 830
      || window.innerHeight < 730;
  }
}
