import {Injectable} from '@angular/core';

import {TranslateService} from '@ngx-translate/core';

import {MapService} from './map.service';
import {SettingsService} from './settings.service';
import {EventsService} from './events.service';
import {Subscription} from 'rxjs';
import {Constants} from "../var/constants";

export class LayerInfo {
  localized: {
    de: {
      title: string;
    };
    en: {
      title: string;
    };
    nl: {
      title: string;
    };
  };
  title: string;
  legend?: string;
  selected?: boolean;
}

export class WMSLayerInfo extends LayerInfo {
  url: string;
  layer: string;
  version?: string;
}

export class OverlayInfo extends LayerInfo {
  layer: any;
}

export class ToggleOverlayParams {
  layerInfo?: OverlayInfo;
  layer?: any;
  checked?: boolean;
  before?: string;
  fireEvent?: boolean;
}

export class ToggleBaseLayerParams {
  layerInfo?: WMSLayerInfo;
  layer?: any;
  save?: boolean;
  fireEvent?: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class LayerService {

  activeBaseLayer: any;
  bahntrassenLayer: any;

  baseWidth: 5;
  baseZoom: 15;

  constructor(
    private events: EventsService,
    private mapService: MapService,
    private settingsService: SettingsService,
    private translate: TranslateService,
  ) {
    this.events.subscribe('map:destroy', () => this.onMapDestroy());
  }

  createLayer(layerInfo: WMSLayerInfo): any {
    const lang = this.translate.currentLang;
    return {
      id: layerInfo.localized[lang].title,
      type: 'raster',
      source: layerInfo.layer,
      paint: {}
    };
  }

  async setBaseLayer(params: ToggleBaseLayerParams) {
    if (params.layerInfo?.layer) {
      console.log(params.layerInfo.layer);

      let style: string;
      switch(params.layerInfo.layer) {
        case 'orthofotos':
          style = Constants.URL_MAPTILES_ORTHOFOTOS;
          break;
        case 'stadtplanwerk':
          style = Constants.URL_MAPTILES_STADTPLANWERK;
          break;
        default:
          style = Constants.URL_MAPTILES_OPENMAPTILES;
      }
      this.mapService.setStyle(style);
      if (params.fireEvent) {
        this.events.publish('layer:set-base-layer', style);
      }
      if (params.save) {
        await this.settingsService.set('map-style', style);
      }
    }





    // params.save = params.save !== false; // true by default
    // const doAdd = async () => {
    //   this.mapService.addLayer(this.activeBaseLayer, false, ['baselayersbefore']);
    //   if (params.fireEvent) {
    //     this.events.publish('layer:set-base-layer', this.activeBaseLayer);
    //   }
    //   if (params.save) {
    //     await this.settingsService.set('active-base-layer', this.activeBaseLayer);
    //   }
    // };
    //
    // if (this.activeBaseLayer) {
    //   this.mapService.removeLayer(this.activeBaseLayer.id);
    //   this.activeBaseLayer = null;
    //   if (params.fireEvent) {
    //     this.events.publish('layer:set-basemap', null);
    //   }
    // } else {
    //   await this.mapService.rmVectorLayers();
    // }
    // if (params.layer) {
    //   this.activeBaseLayer = params.layer;
    // } else if (params.layerInfo) {
    //   if (params.layerInfo.layer) {
    //     // wms layer
    //     this.activeBaseLayer = this.createLayer(params.layerInfo);
    //   } else {
    //     // show vector base layer
    //     await this.mapService.addVectorLayers();
    //   }
    // }
    // if (this.activeBaseLayer) {
    //   if (!this.mapService.getLayer('baselayersbefore')) {
    //     this.subscriptions.push(this.events.subscribe('map:added-empty-layers', async () => await doAdd()));
    //   } else {
    //     await doAdd();
    //   }
    // } else if (params.save) {
    //   await this.settingsService.remove('active-base-layer');
    // }
  }

  // async restoreBaseLayer() {
  //   const baseLayer = await this.settingsService.get('active-base-layer');
  //   if (baseLayer) {
  //     await this.setBaseLayer({layer: baseLayer, fireEvent: true});
  //   }
  // }

  /**
   * Toggles visibility for a specified layer on the map
   */
  toggleOverlay(params: ToggleOverlayParams) {
    if (!params.layer && params.layerInfo) {
      // params.layer = this.createLayer(params.layerInfo, 'overlay');
      params.layer = params.layerInfo.layer;
      if (params.checked === null || params.checked === undefined) {
        params.checked = params.layerInfo.selected;
      }
    }
    params.checked = !!params.checked;
    if (!params.before) {
      params.before = 'roadnetworksbefore';
    }
    if (params.layer) {
      if (params.checked) {
        console.log('adding ' + params.layer.id);
        this.mapService.addLayer(params.layer, false, params.before);
        if (params.layer.id === 'Bahntrassen') {
          this.bahntrassenLayer = params.layer;
        } else if (this.bahntrassenLayer && !params.layer.id.includes('Gesperrt')) {
          this.mapService.addLayer(this.bahntrassenLayer, false, params.before);
        }
        if (params.fireEvent) {
          this.events.publish('layer:add-overlay', params.layer);
        }
      } else {
        console.log('removing ' + params.layer.id);
        this.mapService.removeLayer(params.layer.id);
        if (params.layer.id === 'Bahntrassen') {
          this.bahntrassenLayer = null;
        }
        if (params.fireEvent) {
          this.events.publish('layer:remove-overlay', params.layer);
        }
      }
      if (params.layerInfo.title === 'Touristisches Knotenpunktnetz mit Sperrungen') {
        this.events.publish('map:node-network-selection-change', params);
      }
    }
  }

  async onMapDestroy() {
    // clear base layer
    await this.setBaseLayer({save: false});
    this.bahntrassenLayer = null;
  }
}
